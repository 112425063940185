const state = () => ({
    newClientModal: false,
    newUserModal: false
});
const mutations = {
    updateField(state, data) {
        state[data.field] = data.value;
    }
};
const actions = {};

const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
