import {generateId} from "@/util/genericUtilityFunctions";
import dayjs from "dayjs";

export const intializeState = {
    // apptData: {},
    // apptItems: {},
    // // client: {},
    // cancelNoShowServiceCodes: [],
    // invoiceId: 0,
    // sessionNumber: undefined,
    // record: {},
    // recId: null,
    // serviceItems: [],


    globalLists: {
        services: [],
        counselors: [],
        dx_codes: [],
        cancel_no_show_service_codes: [],
    },


    // PWD
    client: {},
    insurances: [],
    company: {},
    note_history: [],
    appts: [],
    appt_info: {},

    diagnosis: [{
        data_id: generateId(),
        primary: true,
        selected: null // will be an id of a service item
    }],
    counselors: [],
    assessment: {
        behavior: [],
        appearance: [],
        mood: [],
        affect: [],
        speech: [],
        thought: []
    },
    risks: {
        suicidal: "No",
        homicidal: "No",
        hideRisks: false,
        details: {
            suicidal: "",
            homicidal: "",
        }
    },
    toxicology: {},
    treatment_prog: {
        goals: [],
        objectives: [],
        interventions: []
    },
    notes: {
        group: '',
        individual: '',
        hide: false
    },
    recommends: '',
    places: [{id: '11', office_name: 'Office'}, {id: '02', office_name: 'Telehealth'}],
    place_of_service: null,
    services: [],
    signature_ids: [],
    user_signature: {},
    updating: false,
    original: {},
    pn: {id: null},
    is_addendum: false
}

const state = () => (JSON.parse(JSON.stringify({...intializeState})));

const mutations = {
    async setState(state, {key, value}) {
        state[key] = value
    },

    setValue(state, {stateKey, objKey, value}) {
        state[stateKey][objKey] = value;
    },

    setGlobals(state, {key, value}) {
        state.globalLists[key] = value
    },

    setMeta(state, input) {
        Object.keys(input).forEach(key => {
            state[key] = input[key]
        })
    },

    clearState(state) {
        Object.keys(intializeState).forEach(key => {
            state[key] = JSON.parse(JSON.stringify(intializeState[key]))
        })

    },

    addToOriginal(state, obj) {
        Object.keys(obj).forEach(key => {
            state.original[key] = JSON.parse(JSON.stringify(obj[key]))
        })
    }
};
const actions = {
    set_services({commit}, services) {

        const updatedServices = services.map(s => ({
            data_id: generateId(),
            units: s.units,
            revenue_code: s.revenue_code,
            modifier: s.modifier || Array.from({length: 4}, () => ""),
            name: s.name || '',
            service_code: s.service_code || '',
            service_code_id: s.service_code_id || null,
        }))

        commit('addToOriginal', {services: updatedServices})
        commit('setState', {key: 'services', value: updatedServices})
    },

    set_pn({commit, state}, pn) {
        let original = {treatment_prog: {}}

        commit('setValue', {stateKey: 'pn', objKey: 'id', value: pn.id})

        try {
            const pn_diags = JSON.parse(pn.diags)

            const pn_diag_value = pn_diags.filter(d => (d.selected.id || typeof d.selected === 'number')).map(d => ({
                ...d,
                selected: d.selected?.id ?? d.selected
            })).addDataId()
            original.diagnosis = pn_diag_value
            commit('setState', {key: 'diagnosis', value: pn_diag_value})
        } catch {
            throw new Error("Error parsing diagnosis")
        }


        try {
            const unfiltered_counselors = JSON.parse(pn.counselors) || []
            let counselors = unfiltered_counselors?.addDataId() ?? []
            if (counselors.length && !counselors.some(c => c.isPrimary)) {
                counselors = counselors.map((c, idx) => ({...c, isPrimary: idx === 0}))
            }

            original.counselors = counselors
            commit('setState', {key: 'counselors', value: counselors})
        } catch {
            throw new Error('Counselors not processed')
        }

        try {
            const assessment = JSON.parse(pn.assessment)
            const value = Object.entries(assessment).reduce((existing, [key, value]) => {
                // there was an old key stored in the db
                if (key === 'isNormalized') {
                    return existing
                }

                const newKey = key === "thoughtProcess" ? "thought" : key

                return {
                    ...existing,
                    [newKey]: assessment[key]?.filter(v => v && v.length) ?? []
                }
            }, {})

            original.assessment = value
            commit('setState', {key: 'assessment', value})
        } catch (e) {
            throw new Error('Error parsing assessment')
        }

        try {
            const risks = JSON.parse(pn.risks)
            original.risks = risks
            commit('setState', {key: 'risks', value: risks})
        } catch {
            throw new Error('Error parsing risks')
        }

        try {
            if (pn) {
                // const goals_raw = typeof pn.goals === 'string' ? JSON.parse(pn.goals) : pn.goals
                // let temp_goals
                // if (goals_raw.goals) {
                //     temp_goals = goals_raw.goals
                // } else if (goals_raw) {
                //     temp_goals = goals_raw
                // } else {
                //     temp_goals = []
                // }
                // const goals = temp_goals.addDataId().map(g => ({
                //     ...g,
                //     edit: false
                // })) ?? []
                // original.treatment_prog.goals = goals
                // commit('setState', {key: 'treatment_prog', value: {...state.treatment_prog, goals}})

                // const objectives_raw = typeof pn.objectives === 'string' ? JSON.parse(pn.objectives) : pn.objectives
                // const objectives = objectives_raw?.addDataId().map(e => ({...e, edit: false})) ?? []
                // original.treatment_prog.objectives = objectives
                // commit('setState', {key: 'treatment_prog', value: {...state.treatment_prog, objectives}})

                // const interventions_raw = typeof pn.interventions === 'string' ? JSON.parse(pn.interventions) : pn.interventions
                // const interventions = interventions_raw?.addDataId().map(e => ({...e, edit: false})) || []
                // original.treatment_prog.interventions = interventions
                // commit('setState', {key: 'treatment_prog', value: {...state.treatment_prog, interventions}})

                let goals, interventions, objectives

                try {
                    goals = typeof pn.goals === 'string' ? JSON.parse(pn.goals) : pn.goals
                    interventions = typeof pn.interventions === 'string' ? JSON.parse(pn.interventions) : pn.interventions
                    objectives = typeof pn.objectives === 'string' ? JSON.parse(pn.objectives) : pn.objectives
                } catch (e) {
                    throw new Error("Could not parse treatmen_prog")
                }

                let newGoals = []
                let newObjectives = []
                let newInterventions = []


                if (goals.goals) {
                    for (let g of goals.goals) {
                        newGoals.push({
                            goal: g.goal,
                            checked: true,
                            asam_dimension: g.asam_dimension || 0,
                            asam_dimension_text: g.asam_dimension_text || null
                        })

                        if (g.objectives) {
                            let oj = g.objectives.map(o => ({
                                objective: o.objective,
                                checked: o.checked,
                                asam_dimension: o.asam_dimension || 0,
                                asam_dimension_text: o.asam_dimension_text || null
                            }))
                            newObjectives.push(...oj)
                        }

                        if (g.interventions) {
                            let it = g.interventions.map(i => ({
                                intervention: i.intervention,
                                checked: i.checked,
                                asam_dimension: i.asam_dimension || 0,
                                asam_dimension_text: i.asam_dimension_text || null
                            }))
                            newInterventions.push(...it)
                        }
                    }
                } else if (goals.length) {
                    newGoals.push(...goals.map(g => ({
                        goal: g.goal,
                        checked: g.checked || true,
                        asam_dimension: g.asam_dimension || 0,
                        asam_dimension_text: g.asam_dimension_text || null
                    })))
                }

                if (goals.goalsAdded?.length) {
                    newGoals.push(...goals.goalsAdded)
                }

                if (goals.objectivesAdded?.length) {
                    newObjectives.push(...goals.objectivesAdded)
                }

                if (goals.interventionsAdded?.length) {
                    newInterventions.push(...goals.interventionsAdded)
                }

                newInterventions.push(...interventions.map(i => ({
                    intervention: i.intervention,
                    checked: i.checked,
                    asam_dimension: i.asam_dimension || 0,
                    asam_dimension_text: i.asam_dimension_text || null
                })))
                newObjectives.push(...objectives.map(i => ({
                    objective: i.objective,
                    checked: i.checked,
                    asam_dimension: i.asam_dimension || 0,
                    asam_dimension_text: i.asam_dimension_text || null
                })))

                const prepareForSave = (arr) => {
                    return arr.addDataId().map(a => ({...a, edit: false}))
                }

                const treatment_prog = {
                    goals: prepareForSave(newGoals),
                    objectives: prepareForSave(newObjectives),
                    interventions: prepareForSave(newInterventions)
                }

                original.treatment_prog = treatment_prog
                commit('setState', {key: 'treatment_prog',
                    value: {
                        ...state.treatment_prog,
                        ...treatment_prog
                    }
                })

            }
        } catch (e) {
            throw new Error('Error parsing treatment prog')
        }

        try {
            const notes = JSON.parse(pn.summary)
            original.notes = notes
            commit('setState', {key: 'notes', value: notes})


            const {recommends} = pn
            original.recommends = recommends
            commit('setState', {key: 'recommends', value: recommends})
        } catch {
            throw new Error('Error parsing notes')
        }

        try {
            const place_of_service = JSON.parse(pn.place_of_service) || intializeState.places[0]
            original.place_of_service = place_of_service
            commit('setState', {key: 'place_of_service', value: place_of_service})
        } catch {
            throw new Error('Error parsing place of service')
        }

        try {
            const signature_ids = JSON.parse(pn.signature_ids) || []
            original.signature_ids = signature_ids
            commit('setState', {key: 'signature_ids', value: signature_ids})
        } catch {
            throw new Error('Error parsing signature ids')
        }

        commit('addToOriginal',
            original)
    },

    // ============================================================================================
    // DIAGNOSIS
    add_diagnosis_code({commit, state}) {
        const value = state.diagnosis.push({
            data_id: generateId(),
            primary: !state.diagnosis.length,
            selected: null // will be an id of a service item
        })
        commit('setState', 'diagnosis', value)
    },
    remove_diagnosis_code({state, commit}, data_id) {
        const item = state.diagnosis.find(item => item.data_id === data_id)
        const value = state.diagnosis.filter(item => item.data_id !== data_id)
        if (item.primary && value.length) {
            value[0].primary = true
        }
        commit('setState', {key: 'diagnosis', value})
    },
    set_diagnosis_code({state, commit}, {data_id, id}) {
        const value = state.diagnosis.map(item => {
                if (item.data_id === data_id) {
                    item.selected = id
                }
                return item
            }
        )
        commit('setState', 'diagnosis', value)
    },
    set_diagnosis_primary({state, commit}, data_id) {
        const value = state.diagnosis.map(item => {
                item.primary = item.data_id === data_id;
                return item
            }
        )
        commit('setState', 'diagnosis', value)
    },
    set_diagnosis_from_tp({state, commit}, tp) {
        const has_primary = tp.diagnosis_codes.some(d => d.primary)

        const value = tp.diagnosis_codes.map((d, idx) => ({
            data_id: d.data_id || generateId(),
            selected: typeof d.selected === 'number' ? d.selected : d.selected.id,
            primary: has_primary ? d.primary : idx === 0
        })).filter(d => d.selected)

        commit('addToOriginal', {diagnosis: value})
        commit('setState', {key: 'diagnosis', value})
    },

    // ============================================================================================
    // COUNSELORS
    set_counselors_from_appt({state, commit}, appt_info) {
        const value = appt_info.counselors.map((c, idx) => ({
            id: c.id,
            name: c.name,
            isPrimary: idx === 0,
            data_id: generateId()
        }))

        commit('setState', {key: 'counselors', value})
    },

    add_counselor({commit, state}) {
        const value = state.counselors.push({
            data_id: generateId(),
            id: null,
            name: '',
            isPrimary: !state.counselors.length
        })
        commit('setState', 'counselors', value)
    },

    remove_counselor({state, commit}, data_id) {
        const item = state.counselors.find(item => item.data_id === data_id)
        const value = state.counselors.filter(item => item.data_id !== data_id)
        if (item.isPrimary && value.length) {
            value[0].isPrimary = true
        }
        commit('setState', {key: 'counselors', value})
    },
    set_counselor({state, commit}, {data_id, id}) {
        const value = state.counselors.map(item => {
                if (item.data_id === data_id) {
                    item.id = id
                }
                return item
            }
        )
        commit('setState', 'counselors', value)
    },
    set_counselor_primary({state, commit}, data_id) {
        const value = state.counselors.map(item => {
                item.isPrimary = item.data_id === data_id;
                return item
            }
        )
        commit('setState', 'counselors', value)

    },

    set_counselor_from_tp({state, commit}, tp) {
        const value = tp.counselors.filter(d => d.id).addDataId()

        commit('addToOriginal', {counselors: value})
        commit('setState', {key: 'counselors', value})
    },

    // ============================================================================================
    // ASSESSMENT
    normalize_assessment({state, commit}, assessment) {
        commit('setState', {key: 'assessment', value: assessment})
    },

    clear_assessment({commit, state}) {
        const value = {}

        Object.keys(state.assessment).forEach(key => {
            value[key] = []
        })

        commit('setState', {key: 'assessment', value})
    },

    toggle_assessment({state, commit}, {category, optn}) {
        const category_value = state.assessment[category]
        let value

        if (optn === 'Not Assessed') {
            if (category_value === [optn]) {
                value = []
            } else {
                value = [optn]
            }
        } else {
            if (category_value.includes(optn)) {
                value = category_value.filter(item => item !== optn && item !== 'Not Assessed')
            } else {
                value = [...category_value, optn].filter(item => item !== 'Not Assessed')
            }
        }

        // if (category_value.includes(optn)) {
        //     value = category_value.filter(item => item !== optn)
        // } else {
        //     value = [...category_value, optn]
        // }
        commit('setState', {
            key: 'assessment',
            value: {
                ...state.assessment, [category]: value
            }
        })
    },

    // ============================================================================================
    // RISKS

    set_risk_hide_fields({state, commit},) {
        const value = !state.risks.hideRisks
        commit('setState', {key: 'risks', value: {...state.risks, hideRisks: value}})
    },

    set_risks_bools({state, commit}, {field, value}) {
        commit('setState', {
            key: 'risks',
            value: {
                ...state.risks,
                [field]: value
            }
        })
    },

    set_risk_details({state, commit}, {field, value}) {
        commit('setState', {key: 'risks', value: {...state.risks, details: {...state.risks.details, [field]: value}}})
    },

    // ============================================================================================
    // TREATMENT GOALS
    set_treatment_prog_from_tp({state, commit}, tp) {
        const value = tp.treatment_prog.filter(d => d.id).addDataId()

        commit('addToOriginal', {treatment_prog: value})
        commit('setState', {key: 'treatment_prog', value})
    },

    add_treatment_goal({commit, state}, {type}) {

    },

    // ============================================================================================
    // TREATMENT PROGRESS
    set_goals_from_tp({state, commit}, tp) {
        const asam_options = [
            { id: 0, text: 'N/A' },
            { id: 1, text: 'ASAM Dimension #1' },
            { id: 2, text: 'ASAM Dimension #2' },
            { id: 3, text: 'ASAM Dimension #3' },
            { id: 4, text: 'ASAM Dimension #4' },
            { id: 5, text: 'ASAM Dimension #5' },
            { id: 6, text: 'ASAM Dimension #6' },
        ]

        const goals = []
        const interventions = []
        const objectives = []

        for (let g of tp.goals) {
            const asam_dimension_text = g.asam_dimension > 0 ? asam_options.find(o => o.id === g.asam_dimension)?.text : null

            if (g.goal) {
                goals.push({
                    data_id: g.data_id || generateId(),
                    goal: g.goal,
                    original: true,
                    edit: false,
                    checked: true,
                    asam_dimension: g.asam_dimension,
                    asam_dimension_text
                })
            }


            for (let i of g.interventions) {
                if (i.intervention) {
                    interventions.push({
                        data_id: i.data_id || generateId(),
                        intervention: i.intervention,
                        original: true,
                        edit: false,
                        checked: true,
                        asam_dimension: g.asam_dimension,
                        asam_dimension_text
                    })
                }
            }

            for (let o of g.objectives) {
                if (o.objective) {
                    objectives.push({
                        data_id: o.data_id || generateId(),
                        objective: o.objective,
                        original: true,
                        edit: false,
                        checked: true,
                        asam_dimension: g.asam_dimension,
                        asam_dimension_text
                    })
                }
            }
        }

        commit('setState', {
            key: 'treatment_prog',
            value: {
                goals, interventions, objectives
            }
        })
    },

    add_treatment_prog({commit, state}, {type, data_id}) {
        const value = {
            ...state.treatment_prog,
            [type]: [...state.treatment_prog[type], {
                data_id,
                original: false,
                edit: true,
                checked: true,
                [type]: ""
            }]
        }
        commit('setState', {key: 'treatment_prog', value})
    },

    set_treatment_prog_text({state, commit}, {type, data_id, text}) {
        const value = {
            ...state.treatment_prog, [type]: state.treatment_prog[type].map(item => {
                    if (item.data_id === data_id) {
                        item[type.slice(0, -1)] = text
                        item.original = false
                    }
                    return item
                }
            )
        }
        commit('setState', 'treatment_prog', value)
    },

    treatment_prog_toggle_checked({state, commit}, {type, data_id}) {
        const value = {
            ...state.treatment_prog, [type]: state.treatment_prog[type].map(item => {
                    if (item.data_id === data_id) {
                        item.checked = !item.checked
                    }
                    return item
                }
            )
        }
        commit('setState', {key: 'treatment_prog', value})
    },

    delete_treatment_prog({state, commit}, {type, data_id}) {
        const value = {
            ...state.treatment_prog, [type]: state.treatment_prog[type].filter(item => item.data_id !== data_id)
        }
        commit('setState', {key: 'treatment_prog', value})
    },

    treatment_toggle_edit({state, commit}, {type, data_id}) {
        const value = {
            ...state.treatment_prog, [type]: state.treatment_prog[type].map(item => {
                    if (item.data_id === data_id) {
                        item.edit = !item.edit
                    }
                    return item
                }
            )
        }
        commit('setState', {key: 'treatment_prog', value})
    },

    // ============================================================================================
    // NOTES / RECOMMENDS
    set_new_notes_summary_from_appt({state, commit}, {appt_notes, appt_type}) {
        switch (appt_type) {
            case 'Individual':
                commit('setState', {key: 'notes', value: {...state.notes, individual: appt_notes}})
                break;
            case 'Group':
                commit('setState', {key: 'notes', value: {...state.notes, group: appt_notes}})
                break;
            case 'Family':
                commit('setState', {key: 'notes', value: {...state.notes, family: appt_notes}})
                break;
            case 'Couple':
                commit('setState', {key: 'notes', value: {...state.notes, couple: appt_notes}})
                break;
            default:
                break;
        }
    },
    edit_note_text({state, commit}, {type, text, append}) {

        if (['group', 'individual', 'couple', 'family'].includes(type)) {
            const value = {
                ...state.notes, [type]: append ? state.notes[type] + text : text
            }
            commit('setState', {key: 'notes', value})
        } else {
            commit('setState', {key: 'recommends', value: append ? state.recommends + text : text})
        }
    },

    toggle_notes_hide: ({state, commit}) => {
        const value = !state.notes.hide
        commit('setState', {key: 'notes', value: {...state.notes, hide: value}})
    },

    // ============================================================================================
    // SERVICES

    set_new_appt_service_items({state, commit}, service_items) {
        const value = service_items.addDataId()

        commit('setState', {key: 'services', value})

        const place = state.places.find(p => p.id === service_items[0].place_of_service_code)

        commit('setState', {key: 'place_of_service', value: place})
    },

    set_place_of_service({state, commit}, {value}) {
        commit('setState', {key: 'place_of_service', value})
    },


    add_service({commit, state}) {
        const value = [...state.services, {
            data_id: generateId(),
            units: null,
            revenue_code: null,
            modifier: Array.from({length: 4}, () => ""),
            name: "",
            service_code: '',
            service_code_id: null,
        }]
        commit('setState', {key: 'services', value})
    },

    select_service({commit, state}, {data_id, service_code_id}) {
        const new_service = state.globalLists.services.find(item => item.service_code_id === service_code_id)

        const value = state.services.map(s => {
            if (s.data_id === data_id) {
                s.service_code_id = new_service.service_code_id
                s.description = new_service.description
                s.service_code = new_service.service_code
            }
            return s
        })
        commit('setState', {key: 'services', value})
    },

    delete_service({commit, state}, {data_id}) {
        const value = state.services.filter(item => (item.data_id !== data_id))

        commit('setState', {key: 'services', value})
    },

    set_modifier({commit, state}, {data_id, index, new_value}) {
        const value = state.services.map(item => {
            if (item.data_id === data_id) {
                item.modifier[index] = new_value
            }
            return item
        })
        commit('setState', {key: 'services', value})
    },

    set_units({commit, state}, {data_id, new_value}) {
        const value = state.services.map(item => {
            if (item.data_id === data_id) {
                item.units = new_value
            }
            return item
        })
        commit('setState', {key: 'services', value})
    },

    set_revenue({commit, state}, {data_id, new_value}) {
        const value = state.services.map(item => {
            if (item.data_id === data_id) {
                item.revenue_code = new_value
            }
            return item
        })
        commit('setState', {key: 'services', value})
    },

    // ============================================================================================
    // SIGNATURES
    set_updating: ({commit, state}, value) => {
        commit('setState', {key: 'updating', value})
    },

    add_signature({commit, state}, signature) {
        const siggy = {
            id: signature.user_id,
            signatureId: signature.id,
            signedAt: new Date(),
            // signedAtUTCOnServer: signature.dayt_create,
            type: "user"
        }
        const value = [...state.signature_ids, siggy]
        commit('setState', {key: 'signature_ids', value})
        commit('setState', {key: 'is_addendum', value: false})
    },

    create_addendum({commit}) {
        commit('setState', {key: 'signature_ids', value: []})
        commit('setState', {key: 'is_addendum', value: true})
    },

    reset_original({state, commit}) {
        const value = {
            ...state.original,
            assessment: state.assessment,
            counselors: state.counselors,
            diagnosis: state.diagnosis,
            notes: state.notes,
            place_of_service: state.place_of_service,
            recommends: state.recommends,
            risks: state.risks,
            services: state.services,
            signature_ids: state.signature_ids,
            treatment_prog: state.treatment_prog,
        }
        commit('setState', {key: 'original', value})
    },

    cancel_addendum({commit, state}) {
        const original = state.original

        Object.keys(original).forEach(key => {
            commit('setState', {key, value: original[key]})
        })

        commit('setState', {key: 'is_addendum', value: false})
    },

    set_addendum({commit}, { value}) {
        commit('setState', {key: 'is_addendum', value})
    },


    // ============================================================================================
    // OTHER / META
    set_appt_times({commit, state}, {start, end}) {

        commit('setState', {
            key: 'appt_info', value: {
                ...state.appt_info,
                dayt_appt_start: start,
                dayt_appt_end: end
            }
        })
    },

    set_pn_id({commit, state}, id) {
        commit('setValue', {stateKey: 'pn', objKey: 'id', value: id})
    }

};

const getters = {
    dx_codes: (state) => {
        const selectedIds = state.diagnosis.map(item => item.selected);
        return state.globalLists.dx_codes.map(item => {
            return {
                ...item,
                $isDisabled: selectedIds.includes(item.id)
            }
        })
    },

    counselor_optns: (state) => {
        const selectedIds = state.counselors.map(item => item.id);
        return state.globalLists.counselors.map(item => {
            return {
                ...item,
                $isDisabled: selectedIds.includes(item.id)
            }
        })
    },
    get_service_options: state => {
        const cancelled_codes = state.globalLists.cancel_no_show_service_codes.map(item => item.service_code_id);

        const isCancelled = state.services.some(service => cancelled_codes.includes(service.service_code_id));

        if (isCancelled) {
            return state.globalLists.cancel_no_show_service_codes
        } else {
            return state.globalLists.services.map(item => {
                return {
                    ...item,
                    $isDisabled: state.services.some(service => service.service_code_id === item.service_code_id)
                }
            })
        }
    },
    appt_is_cancelled: state => {
        const cancelled_codes = state.globalLists.cancel_no_show_service_codes.map(item => item.service_code_id);

        return state.services.some(service => cancelled_codes.includes(service.service_code_id));
    },
    sessions: state => {
        return state.appts.filter(appt => (dayjs(state.appt_info.dayt_appt_start).isAfter(dayjs(appt.dayt_appt_start)))).length + 1
    },
    has_tox: state => {
        return state.toxicology ? "Yes" : "No"
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
