<template>
    <ValidationProvider :rules="getRules" v-slot="field" :vid="name" :name="name">
        <label :for="id" :class="labelClass">{{ labelText }}</label>
        $<input
            :name="name"
            :type="type"
            :id="id"
            :class="inputClass"
            :placeholder="placeholder"
            :value="value/100.00"
            :disabled="disabled"
            @input="$emit('input', $event.target.value)"
            :autocomplete="autocomplete"
            @blur="$emit('blur', $event)"
            @keydown="$emit('keydown', $event)"
        />
        <span :class="errorClass">{{ field.errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'Pennies',
        components: { ValidationProvider },
        props: {
            type: String,
            name: String,
            id: String,
            label: String,
            labelClass: String,
            inputClass: String,
            errorClass: String,
            value: String,
            placeholder: String,
            rules: String,
            required: Boolean,
            disabled: Boolean,
            disableAutocomplete: Boolean,
            newPassword: Boolean,
        },
        computed: {
            labelText() {
                return this.label + (this.required ? '*' : '');
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
            autocomplete() {
                if (this.newPassword) {
                    return 'new-password';
                }

                if (this.disableAutocomplete) {
                    return 'off';
                }

                return 'on';
            },
        },
    };
</script>
