var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Boolean(this.user && this.$route.path!="/select-company"))?_c('Layout',{attrs:{"id":"main-layout"}},[_c('NewClientModal',{attrs:{"open":_vm.$store.state.modals.newClientModal},on:{"close":function () {
                _vm.$store.commit('modals/updateField', {
                    field: 'newClientModal',
                    value: !_vm.$store.state.modals.newClientModal
                });
            }}}),_c('NewUserRoleModal',{attrs:{"open":_vm.$store.state.modals.newUserModal},on:{"close":function () {
                _vm.$store.commit('modals/updateField', {
                    field: 'newUserModal',
                    value: !_vm.$store.state.modals.newUserModal
                });
            }}}),_c('router-view')],1):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }