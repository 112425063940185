import Vue from 'vue';
import dayjs from '@/util/dayjs';
import {TimePickerKind} from '@/components/calendar/AppointmentEditor/common/constants';

const initialModalState = {
    deleteEvent: {
        isOpen: false,
    },
    confirmDateChange: {
        isOpen: false,
    },
    saveRecurring: {
        isOpen: false,
    },
    editRRule: {
        isOpen: false,
    },
    autoGenInvoice: {
        isOpen: false,
        selectedClient: null,
        previousAttendance: null,
        nextAttendance: null,
    },
    attendanceWarning: {
        isOpen: false,
        message: null,
        client: null,
        previousAttendance: null,
        nextAttendance: null,
    },
    deleteClient: {
        isOpen: false,
        client: null,
    },
    autopayWarning: {
        isOpen: false,
        client: null,
    },
    deleteService: {
        isOpen: false,
        service: null,
    }
};

const state = () => ({
    originalEvent: {},
    event: {},
    changeTracker: {},
    modals: {...initialModalState},
    globalLists: {
        rooms: [],
        users: [],
        clients: [],
        services: [],
    },
    globalAttrs: {
        autoGenerateInvoices: false,
        clientHasMadePayment: false,
    }
});

const getters = {
    eventHasChanges: (state) => Object.keys(state.changeTracker).length > 0,
    dateHasChanged: (state) => {
        return !dayjs(state.event.dayt_appt_start).isSame(state.originalEvent.dayt_appt_end, 'day');
    },
    globalClientsWithDisabled: (state) => {
        return state.globalLists.clients.map((c) => {
            if (state.event.clients.find((ec) => ec.id === c.id)) {
                return {...c, $isDisabled: true};
            }

            return c;
        });
    },
    globalCounselorsWithDisabled: (state) => {
        return state.globalLists.users.map((u) => {
            if (state.event.counselors.find((c) => c.id === u.id)) {
                return {...u, $isDisabled: true};
            }

            return u;
        });
    },
    globalServicesWithDisabled: (state) => {
        return state.globalLists.services.map((s) => {
            if (state.event.services.find((es) => es.serviceCodeId === s.service_code_id)) {
                return {...s, $isDisabled: true};
            }

            return s;
        });
    },
    globalUsersWithDisabled: (state) => {
        const users = state.event.users?.users;
        const host = state.event.users?.meetingHost;

        return state.globalLists.users.map((u) => {
            if ((users && users.find((eu) => eu.id === u.id)) || host.id === u.id) {
                return {...u, $isDisabled: true};
            }

            return u;
        });
    },
    clientHasAttended: (state) => {
        return Boolean(state.event.clients.some((client) => client.appt_status === "Attended"))
    },
    someClientHasCancelledNoShow: (state) => {
        const cancelNoShowStatuses = ["Cancelled", "Cancelled - Excused", "No Show", "No Show - Excused"]
        return state.event.clients.some((client) => cancelNoShowStatuses.includes(client.appt_status))
    },
    allClientsHaveCancelledNoShow: (state) => {
        const cancelNoShowStatuses = ["Cancelled", "Cancelled - Excused", "No Show", "No Show - Excused"]
        let filteredClients = state.event.clients.filter((client) => cancelNoShowStatuses.includes(client.appt_status))
        return filteredClients.length == state.event.clients.length
    },
    appt_items: (state) => state.event.services,
    someClientHasBeenInvoiced: (state) => {
        return state.event.clients.some((client) => client.invoice_id !== null)
    },
    someClientHasAProgressNote: (state) => {
        return state.event.clients.some((client) => client.progress_note && client.progress_note.id !== null)
    }

};

const mutations = {
    initialize: (state, event) => {
        state.originalEvent = {...event};
        state.event = {...event};
        state.changeTracker = {};
        state.modals = {...initialModalState};
    },
    destroy: (state) => {
        state.originalEvent = {};
        state.event = {};
        state.changeTracker = {};
        state.modals = {...initialModalState};
    },
    updateEvent: (state, {property, value}) => {
        state.event[property] = value;
    },
    openModal: (state, name) => {
        state.modals[name].isOpen = true;
    },
    closeModal: (state, name) => {
        Object.keys(state.modals[name]).forEach((key) => {
            Vue.delete(state.modals[name], key);
        });
        Vue.set(state.modals[name], 'isOpen', false);
    },
    openClientModal: (state, {name, ...modalAttrs}) => {
        state.modals[name].isOpen = true;
        Object.entries(modalAttrs).forEach(([key, value]) => {
            Vue.set(state.modals[name], key, value);
        });
    },
    setChange: (state, {property, value}) => {
        Vue.set(state.changeTracker, property, value);
    },
    removeChange: (state, property) => {
        Vue.delete(state.changeTracker, property);
    },
    resetChanges: (state, property) => {
        Vue.set(state, 'changeTracker', {});
    },
    syncOriginalEvent: (state) => {
        state.originalEvent = {...state.event};
    },
    resetChangeTracker: (state) => {
        state.changeTracker = {};
    },
    setRooms: (state, rooms) => {
        state.globalLists.rooms = rooms;
    },
    setUsers: (state, users) => {
        state.globalLists.users = users;
    },
    setClients: (state, clients) => {
        state.globalLists.clients = clients;
    },
    setServices: (state, services) => {
        state.globalLists.services = services;
    },
    setAutogenerateInvoice: (state, autoGenerate) => {
        state.globalAttrs.autoGenerateInvoices = autoGenerate;
    },
    setClientHasMadePayment: (state, hasMadePayment) => {
        state.globalAttrs.clientHasMadePayment = hasMadePayment;
    },
};

const actions = {
    refreshEvent: ({commit}, event) => {
        commit('resetChanges');
        commit('initialize', event);
    },
    updateTitle: ({commit, state}, value) => {
        commit('updateEvent', {property: 'title', value});

        if (value !== state.originalEvent.title) {
            commit('setChange', {property: 'title', value});
        } else {
            commit('removeChange', 'title');
        }
    },
    toggleIsRequired: ({commit, state}) => {
        commit('updateEvent', {property: 'isRequired', value: !state.event.isRequired});

        if (state.event.isRequired !== !!state.originalEvent.isRequired) {
            commit('setChange', {property: 'isRequired', value: state.event.isRequired});
        } else {
            commit('removeChange', 'isRequired');
        }
    },
    updateDate: ({commit, state}, value) => {
        const newDate = dayjs(value);
        const currentStartTime = dayjs(state.event.dayt_appt_start);
        const currentEndTime = dayjs(state.event.dayt_appt_end);

        const updatedStartDate = new Date(
            newDate.year(),
            newDate.month(),
            newDate.date(),
            currentStartTime.hour(),
            currentStartTime.minute()
        ).toISOString();

        const updatedEndDate = new Date(
            newDate.year(),
            newDate.month(),
            newDate.date(),
            currentEndTime.hour(),
            currentEndTime.minute()
        ).toISOString();

        // Set or remove new_start_day change
        if (newDate.day() !== dayjs(state.originalEvent.dayt_appt_start).day()) {
            commit('setChange', {property: 'new_start_day', value: newDate.day()});
        } else {
            commit('removeChange', 'new_start_day');
        }

        // Set or remove dayt_appt_start change
        if (!newDate.isSame(dayjs(state.originalEvent.dayt_appt_start), 'day')) {
            commit('setChange', {
                property: 'dayt_appt_start',
                value: updatedStartDate,
            });
            commit('setChange', {
                property: 'dayt_appt_end',
                value: updatedEndDate,
            });
        } else {
            commit('removeChange', 'dayt_appt_start');
            commit('removeChange', 'dayt_appt_end');
        }

        // Update new start/end times
        commit('updateEvent', {property: 'dayt_appt_start', value: updatedStartDate});
        commit('updateEvent', {property: 'dayt_appt_end', value: updatedEndDate});
    },
    updateTime: ({commit, state}, {value, kind}) => {
        const changedTime = dayjs(value);
        const originalStartTime = dayjs(state.originalEvent.dayt_appt_start);
        const originalEndTime = dayjs(state.originalEvent.dayt_appt_end);

        switch (kind) {
            case TimePickerKind.Start:
                if (!changedTime.isSame(originalStartTime)) {
                    commit('updateEvent', {property: 'dayt_appt_start', value: changedTime.toDate()});
                    commit('updateEvent', {property: 'dayt_appt_end', value: changedTime.add(1, 'hour').toDate()});
                    commit('setChange', {property: 'dayt_appt_start', value: changedTime.format()});
                    commit('setChange', {property: 'dayt_appt_end', value: changedTime.add(1, 'hour').format()});
                } else {
                    commit('updateEvent', {property: 'dayt_appt_start', value: changedTime.toDate()});
                    commit('removeChange', 'dayt_appt_start');
                    commit('updateEvent', {property: 'dayt_appt_end', value: state.originalEvent.dayt_appt_end});

                    if (dayjs(state.event.dayt_appt_end.isSame(originalEndTime))) {
                        commit('removeChange', 'dayt_appt_end');
                    }
                }
                break;
            case TimePickerKind.End:
                if (!changedTime.isSame(originalEndTime)) {
                    commit('updateEvent', {property: 'dayt_appt_end', value: changedTime.toDate()});
                    commit('setChange', {property: 'dayt_appt_end', value: changedTime.format()});

                    if (changedTime.isBefore(dayjs(state.event.dayt_appt_start))) {
                        commit('updateEvent', {
                            property: 'dayt_appt_start',
                            value: changedTime.subtract(1, 'hour').toDate()
                        });
                        commit('setChange', {
                            property: 'dayt_appt_start',
                            value: changedTime.subtract(1, 'hour').format()
                        });
                    }
                } else {
                    commit('updateEvent', {property: 'dayt_appt_end', value: changedTime.toDate()});
                    commit('removeChange', 'dayt_appt_end');

                    if (changedTime.isBefore(dayjs(state.event.dayt_appt_start))) {
                        commit('updateEvent', {
                            property: 'dayt_appt_start',
                            value: state.originalEvent.dayt_appt_start
                        });
                        commit('removeChange', 'dayt_appt_start');
                    }
                }
                break;
        }
    },
    updateLocation: ({commit, state}, location) => {
        if (state.event.type === 'EVENT') {
            commit('updateEvent', {property: 'location', value: parseInt(location)});

            if (parseInt(location) !== state.originalEvent.location) {
                commit('setChange', {property: 'location', value: parseInt(location)});
            } else {
                commit('removeChange', 'location');
            }
        } else {
            commit('updateEvent', {property: 'place_of_service_code', value: location});
            commit('updateEvent', {property: 'telehealth', value: location === '02'})

            if (location !== state.originalEvent.place_of_service_code) {
                commit('setChange', {property: 'place_of_service_code', value: location});
                commit('setChange', {property: 'telehealth', value: location === '02'});
            } else {
                commit('removeChange', 'place_of_service_code');
                commit('removeChange', 'telehealth');
            }
        }
    },
    updateMeetingHost: ({commit, state}, host) => {
        commit('updateEvent', {
            property: 'users',
            value: {...state.event.users, meetingHost: host}
        });

        if (host.id !== state.originalEvent.users.meetingHost.id) {
            commit('setChange', {property: 'meetingHost', value: host});
        } else {
            commit('removeChange', 'meetingHost');
        }
    },
    updateUsers: ({commit, state}, users) => {
        const compareFn = (a, b) => {
            if (a.id === null || b.id === null) {
                return 1;
            }

            return a.id - b.id;
        };

        const existingUsers = [...state.originalEvent.users.users].sort(compareFn);
        const newUsers = [...users].sort(compareFn);

        const sameList = newUsers.length === existingUsers.length
            && existingUsers.every((user, idx) => user.id === newUsers[idx]?.id);

        commit('updateEvent', {
            property: 'users',
            value: {...state.event.users, users: users}
        });

        if (!sameList) {
            commit('setChange', {property: 'users', value: users});
        } else {
            commit('removeChange', 'users');
        }
    },
    updateClient: ({commit, state, dispatch}, {previousClient, nextClient}) => {
        // always have to update the main event model
        const newClientsList = state.event.clients.map((c) => {
            if (c.id === previousClient.id) {
                return nextClient;
            }

            return c;
        });

        commit('updateEvent', {
            property: 'clients',
            value: newClientsList,
        });

        // check if the previous client was newly added or was previously changed
        const addedClient = state.changeTracker.added_clients?.find((c) => c === previousClient.id);
        const changedClient = state.changeTracker.changed_clients?.find((c) => c.clientId === previousClient.id);

        if (!changedClient && (addedClient || !previousClient.appt_id)) {
            // if we found the previous client in the added clients tracker,
            // we know we need to update that array with the new client
            dispatch('upsertAddedClient', {previousClient, nextClient});
        } else {
            // if not, we need to create or update a new tracked changed client
            dispatch('upsertChangedClient', {previousClient, nextClient});
        }
    },
    upsertAddedClient: ({commit, state}, {previousClient, nextClient}) => {
        if (!state.changeTracker.added_clients) {
            commit('setChange', {
                property: 'added_clients',
                value: [nextClient.id],
            });
        } else {
            // const newAddedClients = state.changeTracker.added_clients.map((c) => {
            //     if (c === previousClient.id) {
            //         return nextClient.id;
            //     }
            //     return c;
            // });

            commit('setChange', {
                property: 'added_clients',
                value: [...state.changeTracker.added_clients, nextClient.id].filter((c) => c !== previousClient.id),
                // value: [...state.changeTracker.added_clients, ...newAddedClients],
            });
        }
    },
    upsertChangedClient: ({commit, state}, {previousClient, nextClient}) => {
        if (!state.changeTracker.changed_clients) {
            commit('setChange', {
                property: 'changed_clients',
                value: [{apptId: previousClient.appt_id, clientId: nextClient.id}],
            });
        } else {
            const newChangedClients = state.changeTracker.changed_clients.map((c) => {
                if (c.clientId === previousClient.id) {
                    return {apptId: c.apptId, clientId: nextClient.id};
                }

                return c;
            });

            commit('setChange', {
                property: 'changed_clients',
                value: newChangedClients,
            });
        }
    },
    removeClient: ({commit, state}, client) => {
        commit('updateEvent', {
            property: 'clients',
            value: state.event.clients.filter((c) => c.id !== client.id),
        });

        if (!client.appt_id) {
            if (!state.changeTracker.added_clients) {
                commit('removeChange', 'added_clients');
            } else {
                commit('setChange', {
                    property: 'added_clients',
                    value: state.changeTracker.added_clients.filter((c) => c !== client.id),
                });
            }

            if (state.changeTracker.added_clients?.length === 0) {
                commit('removeChange', 'added_clients');
            }

            return;
        }

        if (!state.changeTracker.removed_clients) {
            commit('setChange', {
                property: 'removed_clients',
                value: [{apptId: client.appt_id}],
            });
        } else {
            const alreadyTracked = state.changeTracker.removed_clients.find((c) => c.apptId === client.appt_id);

            if (!alreadyTracked) {
                commit('setChange', {
                    property: 'removed_clients',
                    value: [...state.changeTracker.removed_clients, {apptId: client.appt_id}],
                });
            }
        }
    },
    updateAttendance: ({commit, state}, {clientId, nextAttendance}) => {
        const newClients = [...state.event.clients];
        const client = newClients.find((c) => c.id === clientId);

        if (!client) {
            return;
        }

        client.appt_status = nextAttendance;
        commit('updateEvent', {
            property: 'clients',
            value: newClients,
        });
    },
    resetAttendance: ({commit, state}, {clientId, previousAttendance}) => {
        const newClients = [...state.event.clients];
        const client = newClients.find((c) => c.id === clientId);

        if (!client) {
            return;
        }

        client.appt_status = 'invalid-status';
        commit('updateEvent', {
            property: 'clients',
            value: newClients,
        });

        client.appt_status = previousAttendance;
        commit('updateEvent', {
            property: 'clients',
            value: newClients,
        });
    },
    updateCounselors: ({commit, state}, {currentId, nextId}) => {
        const nextCounselor = state.globalLists.users.find((u) => u.id === nextId);

        if (!nextCounselor) {
            return;
        }

        const counselors = state.event.counselors.map((c) => {
            if (c.id === currentId) {
                return {
                    id: nextCounselor.id,
                    name: nextCounselor.name
                        ? nextCounselor.name
                        : `${nextCounselor.first_name} ${nextCounselor.last_name}`,
                };
            }

            return c;
        });

        commit('updateEvent', {property: 'counselors', value: counselors});
        commit('setChange', {property: 'counselors_changed', value: [...counselors]});
    },
    removeCounselor: ({commit, state}, id) => {
        const counselors = state.event.counselors.filter((c) => c.id !== id);
        commit('updateEvent', {property: 'counselors', value: counselors});

        if (id !== null) {
            commit('setChange', {property: 'counselors_changed', value: counselors});
        }
    },
    updateService: ({commit, state}, {currentService, nextService}) => {
        const services = state.event.services.map((s) => {
            if (s.serviceCodeId === currentService.serviceCodeId) {
                return nextService;
            }

            return s;
        });

        commit('updateEvent', {property: 'services', value: services});

        // if the new service is in the array of deleted services
        if (state.changeTracker.removed_services && state.changeTracker.removed_services.map(s => (s.serviceCodeId)).includes(nextService.serviceCodeId)) {

            const newRemovedServices = state.changeTracker.removed_services.filter(s => s.serviceCodeId !== nextService.serviceCodeId)

            // if we no longer have any removed services
            if (!newRemovedServices.length) {
                commit('setChange', {
                    property: 'removed_services',
                    value: null
                })

                // set the added services to remove the existing service as this is the same as the removed service
                if (state.changeTracker.added_services) {
                    commit('setChange', {
                        property: 'added_services',
                        value: [...state.changeTracker.added_services].filter(s => s.serviceCodeId !== currentService.serviceCodeId && s.serviceCodeId !== currentService.service_code_id)
                    })
                } else {
                    commit('setChange', {
                        property: 'added_services',
                        value: [...state.changeTracker?.added_services ?? []].filter(s => !(s.serviceCodeId !== currentService.serviceCodeId || s.serviceCodeId !== currentService.service_code_id)) ?? []
                    })
                }

                // still have removed services
            } else {
                commit('setChange', {
                    property: 'removed_services', value: newRemovedServices
                })

                if (!state.changeTracker.added_services) {
                    commit('setChange', {
                        property: 'added_services',
                        value: [...state.changeTracker?.added_services ?? []].filter(s => s.serviceCodeId !== currentService.serviceCodeId && s.serviceCodeId !== currentService.service_code_id) ?? []
                    });
                } else {
                    if (currentService.serviceCodeId === null) {
                        nextService.service_code_id = nextService.serviceCodeId;
                        commit('setChange', {
                            property: 'added_services',
                            value: [...state.changeTracker?.added_services ?? []].filter(s => s.serviceCodeId !== currentService.serviceCodeId && s.serviceCodeId !== currentService.service_code_id) ?? [],
                        });
                    } else {
                        commit('setChange', {
                            property: 'added_services',
                            value: [...state.changeTracker?.added_services ?? []]?.filter(s => s.serviceCodeId !== currentService.serviceCodeId && s.serviceCodeId !== currentService.service_code_id) ?? []
                        });
                    }
                }
            }
            // just a regular old change without overlap with whats already been deleted
        } else {
            if (!state.changeTracker.added_services) {
                commit('setChange', {
                    property: 'added_services', value: [{
                        service_code_id: nextService.serviceCodeId,
                        serviceCodeId: nextService.serviceCodeId,
                        cost: nextService.cost || nextService.actual_cost,
                    }]
                });
            } else {
                if (currentService.serviceCodeId === null) {
                    nextService.service_code_id = nextService.serviceCodeId;
                    commit('setChange', {
                        property: 'added_services',
                        value: [...state.changeTracker.added_services, nextService],
                    });
                } else {
                    commit('setChange', {
                        property: 'added_services',
                        value: state.changeTracker.added_services.map((s) => {
                            if (s.serviceCodeId === currentService.serviceCodeId) {
                                return {
                                    service_code_id: nextService.serviceCodeId,
                                    serviceCodeId: nextService.serviceCodeId,
                                    cost: nextService.cost || nextService.actual_cost,
                                }
                            }
                            return s;
                        })
                    });
                }
            }
        }


    },
    removeAddedService: ({commit, state}, serviceCodeId) => {
        commit('updateEvent', {
            property: 'services',
            value: state.event.services.filter((s) => s.serviceCodeId !== serviceCodeId),
        });

        const addedServices = state.changeTracker.added_services;

        if (!addedServices) {
            return;
        }

        const newAddedServices = addedServices.filter((s) => s.serviceCodeId !== serviceCodeId);

        if (newAddedServices.length === 0) {
            commit('removeChange', 'added_services');
        } else {
            commit('setChange', 'added_services', newAddedServices);
        }
    },
    removeExistingService: ({commit, state}, service) => {
        const newEventServices = state.event.services.filter((s) => s.serviceCodeId !== service.serviceCodeId);
        commit('updateEvent', {property: 'services', value: newEventServices});

        if (!state.changeTracker.removed_services) {
            commit('setChange', {
                property: 'removed_services',
                value: [{serviceCodeId: service.serviceCodeId}]
            });
        } else {
            if (!state.changeTracker.removed_services.some((s) => s.serviceCodeId === service.serviceCodeId)) {
                commit('setChange', {
                    property: 'removed_services',
                    value: [
                        ...state.changeTracker.removed_services,
                        {serviceCodeId: service.serviceCodeId}
                    ],
                });
            }
        }
    },
    updateNote: ({commit, state}, note) => {
        commit('updateEvent', {property: 'appt_notes', value: note});

        if (state.originalEvent.appt_notes === note || (state.originalEvent.appt_notes === null && note === '')) {
            commit('removeChange', 'changed_summary');
        } else {
            commit('setChange', {property: 'changed_summary', value: note});
        }
    },
    changesSaved: ({commit}) => {
        commit('syncOriginalEvent');
        commit('resetChangeTracker');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
