<template>
    <Layout v-if='Boolean(this.user && this.$route.path!="/select-company")' id='main-layout'>
        <NewClientModal
            :open='$store.state.modals.newClientModal'
            @close="
                () => {
                    $store.commit('modals/updateField', {
                        field: 'newClientModal',
                        value: !$store.state.modals.newClientModal
                    });
                }
            "
        />
        <NewUserRoleModal
            :open='$store.state.modals.newUserModal'
            @close="
                () => {
                    $store.commit('modals/updateField', {
                        field: 'newUserModal',
                        value: !$store.state.modals.newUserModal
                    });
                }
            "
        />
        <router-view/>
    </Layout>
    <router-view v-else/>
</template>

<script>
import {mapState} from 'vuex';
import Layout from '@/components/layout/Layout';
import NewClientModal from '@/components/client-manager/NewClientModal.vue';
import NewUserRoleModal from '@/components/users/NewUserRoleModal';
import {timeoutFunctions} from "@/util/timeoutFunctions";

export default {
    components: {NewUserRoleModal, Layout, NewClientModal},
    computed: {
        ...mapState({
            user: 'user'
        })
    },
    async mounted() {
        let ok2loadjunk = 1;
        if (window.location.pathname == '/auth/puppet') {
            ok2loadjunk = 0;
        }
        if (this.user && this.user.puppet) {
            ok2loadjunk = 0;
        }
        if (ok2loadjunk) {
            const gapiScript = document.createElement('script');
            gapiScript.async = true;
            gapiScript.defer = true;
            gapiScript.src = 'https://apis.google.com/js/api.js';
            document.head.appendChild(gapiScript);

            const zendeskScript = document.createElement('script');
            zendeskScript.async = true;
            zendeskScript.defer = true;
            zendeskScript.id = 'ze-snippet';
            zendeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=12ba503e-0265-4dbf-9636-19124b31341c';
            document.head.appendChild(zendeskScript);
        }

        const tracker = await timeoutFunctions()

        document.addEventListener('keydown', () => tracker.newInteraction())
        document.addEventListener('mousedown', () => tracker.newInteraction())
    }
};
</script>

<style>
/* ### Custom scroll ### */

/* width */
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}
</style>