<template>
    <div>
        <slot name="control" :open="openModal" />

        <div class="overlay" :class="{ open: modalIsOpen, closed: !modalIsOpen }" ref="overlay">
            <div class="content">
                <slot name="content" :close="closeModal" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'BaseModal',
        props: {
            permanent: Boolean,
            startOpen: Boolean,
            stateKey: {
                type: String,
                default: null,
            },
            mutationKey: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                isOpen: false,
            };
        },
        computed: {
            modalIsOpen() {
                if (this.stateKey) {
                    return this.$store.state.newResourceModal[this.stateKey];
                } else {
                    return this.isOpen;
                }
            },
        },
        methods: {
            openModal() {
                this.isOpen = true;
                if (this.stateKey) {
                    this.$store.commit(`newResourceModal/${this.mutationKey}`);
                }
                this.$emit('open');
            },
            closeModal() {
                this.isOpen = false;
                if (this.stateKey) {
                    this.$store.commit('newResourceModal/closeAll');
                }
                this.$emit('close');
            },
            checkOverlayClick(e) {
                if (e.target === this.$refs.overlay && !this.permanent) {
                    this.closeModal();
                }
            },
        },
        mounted() {
            if (this.startOpen) {
                this.openModal();
            }

            window.addEventListener('click', this.checkOverlayClick);
        },
        beforeDestroy() {
            window.removeEventListener('click', this.checkOverlayClick);
        },
    };
</script>

<style scoped>
    .open {
        display: flex;
    }

    .closed {
        display: none;
    }

    .overlay {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 500;
        background: rgba(0, 0, 0, 0.5);
        align-items: center;
        justify-content: center;
    }

    .content {
        background: white;
        max-height: 95%;
        max-width: 95%;
        overflow: auto;
    }
</style>
