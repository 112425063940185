<template>
    <div v-if="1 || loading == false">
        <label :for="id" :class="labelClass">{{ labelText }}</label>
        <select :name="name" :id="id" :value="value" @input="$emit('input', $event.target.value)" :disabled="disabled">
            <option :disabled="disableEmpty" value=""></option>
            <option
                v-for="(option, index) in options"
                :key="index"
                :value="getOptionValue(option)"
                :disabled="disabled"
            >
                {{ getOptionText(option) }}
            </option>
        </select>
    </div>
</template>

<script>

    const api_root = 'clients'

    export default {
        name: 'ClientsDropDown',
        props: {
            value : [String, Number],
        },
        data() {
            return {
                name: 'client_id',
                id: 'client_id',
                label: 'Client',
                labelClass: String,
                errorClass: String,
                required: false,
                disableEmpty: false,
                disabled: false,
                rules: '',
                options: [],
                rows: [],
                loading: true,
                criteriaProps: {}
            };
        },
        methods: {
            getOptionValue(option) {
                return option?.value ?? option;
            },
            getOptionText(option) {
                return option?.text ?? option;
            },
            async load_data() {
                const body = {
                    criteria : this.criteriaProps
                }
                const res = await this.$api.post(`/${api_root}`, body)
                this.rows = res.data.rows

                if (this.rows.length) {
                    this.options = []
                    for (const row of this.rows) {
                        this.options.push({text:row.email, value:row.id})
                    }
                }
                this.$nextTick(() => {
                    this.loading = false
                })
            },
            async init() {
                await this.load_data()
            },
            async updated() {
                await this.init()
            }
        },
        computed: {
            labelText() {
                return this.label + (this.required ? '*' : '');
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
        },
        created(){
            this.load_data();
        }
    };
</script>
