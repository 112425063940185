<template>
    <div class="refresh-wrapper"
         :class="{'showRefreshBN': !!showRefreshBN, 'clickable': states[showRefreshBN].allowKeepAlive}"
         v-if="showRefreshBN"
         @click="clickToaster"
    >
        <p><span class="material-icons">error</span> {{ states[showRefreshBN].msg }}</p>
        <button @click="signOut" class="secondary white" v-if="states[showRefreshBN].btn === 'Sign Out'">
            <span class="material-icons-outlined">refresh</span> Sign Out
        </button>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'RefreshBN',
    data() {
        return {
            states: {
                newBNVersion: {
                    msg: "A New Version of BirchNotes is here! Use this button to sign out and refresh : ",
                    btn: 'Sign Out'
                },
                timeoutWarn: {
                    msg: "You are about to be logged off for inactivity. Click here to remain logged in.",
                    allowKeepAlive: true
                },
                timeout: {
                    msg: "You have been logged out due to inactivity!",
                    btn: 'Sign Out'
                }
            }
        }
    },
    methods: {
        async signOut() {
            try {
                await this.$api.post('/auth/sign-out');
            } catch (err) {
                console.error(err);
            } finally {
                document.cookie = 'chirp_notes_id=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                this.$store.commit('clearUser');
                this.$store.commit('clearGoogleTokens');
                await this.$store.dispatch('recent/clearStore');
                await this.$router.push({name: 'SignIn'});
                window.location.reload();
            }
        },
        async clickToaster () {
            if (this.states[this.showRefreshBN].allowKeepAlive) {
                await this.$api.get('/auth/keep-alive');
            }
        }
    },
    computed: {
        ...mapState({
            showRefreshBN: (state) => state.showRefreshBN
        })
    }
};
</script>