import Vue from 'vue';
import store from '../store';
import { getBasePageName } from '@/util/getBasePageName';

const OPEN_ROUTES = ['SignIn', 'NotFound', 'DynamicForm', 'ResetPassword', 'SuperUserRedirect', 'SelectCompany'];

function isOpenRoute(name) {
    return name && OPEN_ROUTES.includes(name);
}

export function removeHiddenOverflow(_, __, next) {
    document.querySelector('body').style.overflow = 'auto';

    return next();
}

export function preventSignInWhenAuthenticated(to, from, next) {
    if (to.name && (to.name === 'SignIn' || to.name === 'SelectCompany') && store.state.isAuthenticated) {
        return next({ name: 'Dashboard', replace: true });
    }

    return next();
}

export function preventNoNameNavigation(to, from, next) {
    if (!to.name) {
        return next({ name: 'Dashboard' });
    }

    return next();
}

export function preventUnauthenticatedNavigation(to, from, next) {
    const openRoute = isOpenRoute(to.name);

    if (!store.state.isAuthenticated && !openRoute) {
        Vue.toasted.error('You must be signed in to view that page.');
        return next({ name: 'SignIn', replace: true });
    }

    return next();
}

export function preventUnauthorizedNavigation(to, from, next) {
    const openRoute = isOpenRoute(to.name);
    if (to.name === 'UserDetail' && to.params?.userId == store.state.user?.id) {
        return next();
    }

    if (!openRoute && !store.getters.availablePageNames.includes(getBasePageName(to.path))) {
        Vue.toasted.error('Could not find that page.');
        return next({ name: 'NotFound', replace: true });
    }

    return next();
}

//Do actions of things that aren't done well through a computed in the index.js vueX store.
//i.e more api requests, or more set up
export function initialized(to, from, next) {
    if (store.state.user && store.state.initialized == false) {
        store.dispatch('initialize');
    }
    return next();
}

export function trackRecent(to, from, next) {
    if (
        [
            'ClientOverview',
            'ClientDemographics',
            'ClientInsurance',
            'ClientClinical',
            'ClientBilling',
            'ClientDocuments'
        ].includes(to.name) &&
        to.params?.client !== 'new'
    ) {
        //get property name i.e 'record_id' or 'client_id'
        let key = Object.keys(to.params).filter((element) => element.includes('id'))[0];
        //track clients
        store.dispatch('recent/updateRecentlyVisitedClientsInDB', { page: to.name, id: to.params[key], key: key });
    }
    let forms = store.getters['availablePages']
        .filter((element) => element.page_type == 'form')
        .map((element) => element.component_name);

    if (forms.includes(to.name) && to.params?.record_id !== 'new' && to.params?.record_id != '0') {
        //get property name i.e 'record_id' or 'client_id'
        let key = Object.keys(to.params).filter((element) => element.includes('id'))[0];
        //track forms
        store.dispatch('recent/updateRecentlyVisitedFormsInDB', { page: to.name, id: to.params[key], key: key });
    }
    if (['ProgressNote', 'ChartNote'].includes(to.name)) {
        let key = Object.keys(to.params).filter((element) => element.includes('id'))[0];
        if (to.params?.[key] !== 'new' && to.params?.[key] != '0') {
            //
            store.dispatch('recent/updateRecentlyVisitedFormsInDB', { page: to.name, id: to.params[key], key: key });
        }
    }

    // if (['ProgressNote', 'ChartNote'].includes(to.name) && to.params?.record_id !== 'new' && to.params?.record_id != '0') {
    //     //get property name i.e 'record_id' or 'client_id'
    //     let key = Object.keys(to.params).filter((element) => element.includes('id'))[0];
    //     //track forms
    //     store.dispatch('recent/updateRecentlyVisitedFormsInDB', {page: to.name, id: to.params[key], key: key });

    // }
    return next();
}

/**
 * Run logic for persisting the client tabs and it's data.
 * This functionality was built since we can't leverage nested routes as of right now.
 * This functionality isn't being utilized fully until we start refactoring, but can be used.
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
export async function clientOverview(to, from, next) {
    //When user is coming in from a page refresh
    if (
        [
            'ClientOverview',
            'ClientDemographics',
            'ClientInsurance',
            'ClientClinical',
            'ClientBilling',
            'ClientDocuments'
        ].includes(to.name) &&
        !from.name
    ) {
        //intialization
        let key = Object.keys(to.params).filter((element) => element.includes('id'))[0];
        store.commit('persistClientId', to.params[key]);
        window.localStorage.setItem('last_client_id', to.params[key]);
        if (to.params[key] !== 'new') {
            store.dispatch('clientOverview/getClient');
            store.dispatch('clientOverview/getAppointments');
            store.dispatch('clientOverview/getTags');
            store.dispatch('clientOverview/getPayers');
        }
    }
    //When user is coming into a client overview, but not from any of it's own tabs.
    else if (
        [
            'ClientOverview',
            'ClientDemographics',
            'ClientInsurance',
            'ClientClinical',
            'ClientBilling',
            'ClientDocuments'
        ].includes(from.name) == false &&
        [
            'ClientOverview',
            'ClientDemographics',
            'ClientInsurance',
            'ClientClinical',
            'ClientBilling',
            'ClientDocuments'
        ].includes(to.name)
    ) {
        let key = Object.keys(to.params).filter((element) => element.includes('id'))[0];
        store.commit('persistClientId', to.params[key]);
        window.localStorage.setItem('last_client_id', to.params[key]);

        if (to.params[key] !== 'new') {
            store.commit('clientOverview/updateField', {
                field: 'loaded',
                value: false
            });
            store.dispatch('clientOverview/getClient');
            store.dispatch('clientOverview/getAppointments');
            store.dispatch('clientOverview/getTags');
            store.dispatch('clientOverview/getPayers');
        }
    }
    //You are coming in
    else if (
        [
            'ClientOverview',
            'ClientDemographics',
            'ClientInsurance',
            'ClientClinical',
            'ClientBilling',
            'ClientDocuments'
        ].includes(from.name) &&
        [
            'ClientOverview',
            'ClientDemographics',
            'ClientInsurance',
            'ClientClinical',
            'ClientBilling',
            'ClientDocuments'
        ].includes(to.name)
    ) {
        let toKey = Object.keys(to.params).filter((element) => element.includes('id'))[0];
        let fromKey = Object.keys(from.params).filter((element) => element.includes('id'))[0];

        //You are going from one of the tab routes BUT you somehow changed client id... so redispatch everything
        //this can happen via creating a new client on one of these tab pages and then router replace/push to that client
        if (to.params[toKey] !== from.params[fromKey]) {
            //we can use either to or from key, it should result in the same value i,e  to.params[toKey] is 213 === 213 is from.params[fromKey]
            store.commit('persistClientId', to.params[toKey]);
            store.commit('clientOverview/updateField', {
                field: 'loaded',
                value: false
            });
            store.dispatch('clientOverview/getClient');
            store.dispatch('clientOverview/getAppointments');
            store.dispatch('clientOverview/getTags');
            store.dispatch('clientOverview/getPayers');
            window.localStorage.setItem('last_client_id', to.params[toKey]);
        }
    }
    return next();
}
