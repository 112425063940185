<template>
    <span class="radios-row" v-if="options">
        <span :class="'weight-600 right-30 bottom-10 block ' + diff_class">{{ label }}</span>
        <span class="flex bottom-10 me-4" v-for="(option, index) in options" :key="index">
            <input
                :value="option"
                type="radio"
                :name="name"
                :class="inputClass + ' ' + diff_class"
                :checked="value == option"
                @input="$emit('input', option)"
            />
            <label :class="labelClass + ' ' + diff_class" class="margin-0">{{ option }}</label>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'Radios',
        props: {
            id: String,
            name: String,
            value: [Boolean, Number, String],
            label: String,
            inputClass: String,
            labelClass: String,
            disabled: Boolean,
            options: {
                type: Array,
                required: false,
            },
            portal_data: {
                type: Object,
                required: false
            },
            field_name: {
                type: String,
                required: false
            },
        },
        computed: {
            diff_class() {
                let txt_class = 'dark-text'
                if (this.portal_data && this.portal_data.pokeme && this.portal_data.data && this.field_name && this.portal_data.data[this.field_name] && this.portal_data.original_data && this.portal_data.data[this.field_name] != this.portal_data.original_data[this.field_name]) {
                    txt_class = 'dark-red bold'
                }
                return txt_class
            },
        },
    }
</script>
