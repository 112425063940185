<template>
    <div class="form-save">
        <button class="do-not-print-me" :disabled="disabled" v-if='!updating'>
            <slot>Save</slot>
        </button>
        <LoadingButton v-else />
    </div>
</template>

<script>
    // button v-if="canedit"
    // import { ValidationProvider } from 'vee-validate';
    import LoadingButton from '@/components/general/button/LoadingButton';
    export default {
        name: 'ConfirmButton',
        components: { LoadingButton },
        // components: { ValidationProvider },
        props: {
            name: String,
            id: String,
            value: [String, Number],
            canedit : [String, Number],
            updating: {
                type: Boolean,
                default: false
            },
          disabled: {
            type: Boolean,
            default: false
          },
        },
        data() {
            return {
                rules: '',
            };
        },
        methods: {
            // submit_form() {
            //     this.form.submit()
            // },
        }
    }
</script>