<template>
    <div class="container" ref="container">
        <div class="d-flex align-items-center create-resource-main">
            <span class="header-nav-right-content mx-4" role="button" v-if="showMenu" id="the_create_new_dropdown_top_button">
                <ejs-tooltip
                    class="tooltipcontainer"
                    content="Create New"
                    target="#create-resource"
                    cssClass="customtooltip"
                >
                    <img id="create-resource" src="@/assets/px/header-icons-images/apps.png"/>
                </ejs-tooltip>
            </span>
            <!-- Currently made hidden since its not functional -->
                <!-- <span class="header-nav-right-content" role="button">
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Messages"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <img id="target" src="@/assets/px/header-icons-images/chat.png"/>
                    </ejs-tooltip>
                </span>
                <span class="header-nav-right-content mx-4" role="button">
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Notifications"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <img id="target" src="@/assets/px/header-icons-images/notification_bell.png"/>
                    </ejs-tooltip>
                </span> -->
            <!-- Currently made hidden since its not functional -->
            
            <!-- <span class="material-icons mx-4" role="button">grid_view</span>
            <span class="material-icons" role="button">message</span>
            <span class="material-icons mx-4" role="button">notifications</span> -->
        </div>
        <!-- <button class="create-button no-bg" v-if="showMenu" id="the_create_new_dropdown_top_button">
            <span class="material-icons purple">add_box</span> <b>Create New</b>
        </button> -->
        <div class="header-right-dropdown" v-show="dropdownOpen" ref="dropdown">

            <button
                v-if="this.$route.name !== 'AddNewCompany' && userIsSuperAdmin()" 
                class="ms-0"
                @click="gotoAddNewCompany"
            >
                New Company
            </button>

            <button
                id="the_create_user_dropdown_top_button"
                class="ms-0"
             
                @click="
                    () => {
                        $store.commit('modals/updateField', {
                            field: 'newUserModal',
                            value: !$store.state.modals.newUserModal
                        });
                    }
                "
                aria-label="Open New User Modal"
                v-if="[canCreateUsers].some((ability) => ability)"
            >
                New User
            </button>
            <!-- <ImportClientLeadModal
                class="dropdown-btn"
                @close="closeDropdown"
                :openButtonLabel="'New Client'"
                aria-label="Open New Client Modal"
            ></ImportClientLeadModal> -->
            
            <button
                v-if="this.$route.name !== 'AddNewMember'" 
                class="ms-0"
                @click="gotoAddNewClient"
            >
                New Member
            </button>

            <button
                v-if="this.$route.name !== 'Calendar'"
                class="ms-0"
                @click="goToCalendar"
                aria-label="Go to calendar to create a new appointment"
                id="the_create_appt_dropdown_top_button"
            >
                New Appointment
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import NewUserRoleModal from '@/components/users/NewUserRoleModal';
    import ImportClientLeadModal from '@/components/client-manager/ImportClientLeadModal';
    import { Roles} from '@/util/globalConstants'
    //import CreateEventModal from '../calendar/CreateEventModal.vue';

    export default {
        name: 'CreateResourceDropdown',
        components: { ImportClientLeadModal, NewUserRoleModal },
        computed: {
            dropdownOpen() {
                return this.$store.state.newResourceModal.dropdownOpen;
            },
            ...mapGetters(['canCreateUsers']),
            showMenu() {
                return true;
                //Permissions need to be assessed better... leave on for all...
                //return [this.canCreateUsers].some((ability) => ability);
            }
        },
        data(){
            return{
               
            }
           
        },
        methods: {
            // go(){
            //     location.href = 'client-demographics/new';
            // },
            closeDropdown() {
                this.$store.commit('newResourceModal/closeAll');
            },
            setDropdown(event) {
                if (this.$refs.container.contains(event.target)) {
                    if (event.target.id !== 'create-resource') {
                        return;
                    }
                    
                    //This is specifically for the import lead client modal since it's actually contained within target.
                    if (event.target.outerText == 'close' || event.target.outerText == 'Cancel') {
                        this.$store.commit('newResourceModal/closeAll');
                    } else {
                        this.$store.commit('newResourceModal/openDropdown');
                    }
                } else {
                    this.$store.commit('newResourceModal/closeAll');
                }
            },
            goToCalendar() {
                this.$router.push({ name: 'Calendar', params: { preOpenModal: true } });
                //this.$router.push({ params: { preOpenModal: true }})
            },
            gotoAddNewClient() {
                this.$router.push({ name: 'AddNewMember' });
            },
            gotoAddNewCompany() {
                this.$router.push({ name: 'AddNewCompany' });
            },
            userIsSuperAdmin() {
                if (this.$store.state.user.role_id === Roles.SUPER_ADMIN) {
                    return true;
                } else return false;
            },
        },
        mounted() {
            document.addEventListener('click', this.setDropdown);
        },
        beforeDestroy() {
            // if(this.$store.state.newResourceModal.dropdownOpen !== false){
            document.removeEventListener('click', this.setDropdown);
            // }
        }
    };
</script>
