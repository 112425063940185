<template>
    <ValidationProvider :rules="getRules" v-slot="field" :vid="name" :name="name">
        <label :for="id" :class="labelClass">{{ labelText }} <span v-if="labelOptionalText" :class="labelOptionalTextClass">(Optional)</span></label>
        <textarea
            :name="name"
            :type="type"
            :id="id"
            :class="inputClass"
            :placeholder="placeholder"
            :rows="rows"
            :cols="cols"
            :value="value"
            :disabled="disabled"
            @input="$emit('input', $event.target.value)"
            @change="$emit('change', {id:$event.target.id, value:$event.target.value})"
            :autocomplete="autocomplete"
            @blur="$emit('blur', $event)"
            @keydown="$emit('keydown', $event)"
            :maxlength="limit"
        />
        <div v-if="limit" class="do-not-print-me char-counter font-12" style="text-align:right;">{{ characterCounterDisplay(value, limit) }}</div>
        <span v-if="field.errors.length" class="errorText">{{ field.errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'TextArea',
        components: { ValidationProvider },
        props: {
            type: String,
            name: String,
            id: String,
            label: String,
            labelClass: String,
            inputClass: String,
            errorClass: String,
            value: [String, Number, Object, Array],
            placeholder: String,
            rules: String,
            required: Boolean,
            disabled: Boolean,
            disableAutocomplete: Boolean,
            newPassword: Boolean,
            // characterCounter: {
            //     type: Boolean,
            //     default: false
            // },
            limit: {
                type: Number,
                default: 4000
            },
            rows: Number,
            cols: Number,
            labelOptionalText: {
                type: String
            },
            labelOptionalTextClass: String,
            showAsterick: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            labelText() {
                return this.label ? this.label + (this.required && this.showAsterick ? '*' : '') : '';
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
            autocomplete() {
                if (this.newPassword) {
                    return 'new-password';
                }

                if (this.disableAutocomplete) {
                    return 'off';
                }

                return 'on';
            },
        },
        methods: {
            characterCounterDisplay(val, limit) {
                return (val ? val.length : 0) + " / " + limit + " character(s) found";
            },
        },
    };
</script>
