Object.defineProperty(String.prototype, 'toTitleCase', {
    value() {
        let str = this.toString();

        if (str.length === 0) return str;
        if (str.length === 1) return str.toUpperCase();

        let newStr = str
            .split(' ')
            .map((word) => {
                if (word.length === 0) return word;
                if (word.length === 1) return word.toUpperCase();

                let newWord = '';
                newWord += word[0].toUpperCase();
                newWord += word.substring(1).toLowerCase();
                return newWord;
            })
            .join(' ');

        return newStr;
    },
});
