// import Vue from "vue";
import api from '@/util/api';
import { clients, appts, file, settings, users } from '@/util/apiRequests';

const state = () => ({
    recentClients: [],
    recentForms: []
});
const mutations = {
    updateField(state, data) {
        state[data.field] = data.value;
    },
    updateRecentlyVisitedClients(state, {page, id, key}){

        //if recentClients not found, which is good, then add
        if(state.recentClients.some(element => element.id == id) == false){
            //length greater than 3? then pop, then add new one
            if(state.recentClients.length >= 10){
                state.recentClients.pop();
                state.recentClients = [{page: page, id: id, key: key}, ...state.recentClients];
                return;
            }
            //add to array
            state.recentClients = [{page: page, id: id, key: key}, ...state.recentClients];
            return;
        }
        //Found, so place item at the top.
        else {
            let index = state.recentClients.findIndex(element => element.id == id);
            let temp = state.recentClients;
            temp = temp.splice(index, 1);     
            temp = [{page: page, id: id, key: key}, ...state.recentClients];
            state.recentClients = temp;
        }
        return;
        
    },
    updateRecentlyVisitedForms(state, {page, id, key}){
       
        //if recentForms not found, which is good, then add
        if(state.recentForms.some(element => element.id == id) == false){
            //length greater than 3? then pop, then add new one
            if(state.recentForms.length >= 10){
                state.recentForms.pop();
                state.recentForms = [{page: page, id: id, key: key}, ...state.recentForms];
                return;
            }
            //add to array
            state.recentForms = [{page: page, id: id, key: key}, ...state.recentForms];
            return;
        }
        //Found, so place item at the top.
        else {
            let index = state.recentForms.findIndex(element => element.id == id);
            let temp = state.recentForms;
            temp = temp.splice(index, 1);     
            temp = [{page: page, id: id, key: key}, ...state.recentForms];
            state.recentForms = temp;
        }
        return;
    },
    clearStore(state){
        state.recentClients = [];
        state.recentForms = [];
    }
};
const actions = {

    
    async updateRecentlyVisitedClientsInDB({commit, state, rootState}, payload){
        try {
            if(payload.id !== 0 || payload.id !== 'new'){
                //update locally
                await commit('updateRecentlyVisitedClients', {
                    page: payload.page,
                    id: payload.id,
                    key: payload.key,
                })
                //update to server
                await api.put(users.updateUser(rootState.user?.id), { recent_clients: JSON.stringify(state.recentClients)  });
            }
        } catch (error) {
            //
        }  
     },
     async updateRecentlyVisitedFormsInDB({commit, state, rootState}, payload){
        try {
            if(payload.id !== 0 || payload.id !== 'new'){
                //update locally
                await commit('updateRecentlyVisitedForms', {
                    page: payload.page,
                    id: payload.id,
                    key: payload.key,
                })
                //update to server
                await api.put(users.updateUser(rootState.user?.id), { recent_forms: JSON.stringify(state.recentForms)  });
            }
              
        } catch (error) {
            //
        }  
     },
    
    //Grab the recent clients and recent forms and assign those values to their respective Vue X properties.
    async getRecent({rootState, commit}){
        try {
            let { data } = await api.get(users.getUserDetails(rootState.user.id));
            if(data.recentClients){
                //incase parse fails.
                try {
                    let recentClients = JSON.parse(data.recentClients);
                    
                    commit('updateField', {
                        field: 'recentClients',
                        value: recentClients,
                    });
                }catch(error){
                    //
                }
            }
            if(data.recentForms){
                //incase parse fails.
                try {
                    let recentForms = JSON.parse(data.recentForms);
                 
                    commit('updateField', {
                        field: 'recentForms',
                        value: recentForms,
                    });
                }catch(error){
                    //
                }
            }
        }
        catch(error){
            //api error

        }
    },


    async clearStore({commit}){
        commit('clearStore');
    }

};
const getters = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
