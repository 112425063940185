<template>
    <ValidationProvider class="block" :rules="getRules" v-slot="field" :vid="name" :name="name">
        <div class="dropdown-with-search-input-container">
            <input type="text" class='placeholder-input' :name="name" :id="id" :value="value"  />
            <label :for="id" :class="labelClass + ' '">{{ labelText }}</label>
            <b-dropdown :class="` dropdown-with-search-input`" :name="name" :id="id" :value="value" :disabled="disabled">
                <template #button-content>
                    <div class="dropdown-value">
                        <span>{{ valueText }}</span>
                    </div>
                    <div class="expand-icon">
                        <span class="material-icons-outlined">expand_more</span>
                    </div>
                </template>

                <input type="text" placeholder="Search.." id="myInput" @input="filterFunction" v-model="searchVal">
                <span v-if="dropdownOptions.length==0">No Records Found</span>
                <b-dropdown-item v-for="(option, index) in dropdownOptions" :key="index" :value="option.value"
                    @click="optionClickHandler(option)" :disabled="option.disabled">
                    {{ option.value }}</b-dropdown-item>
            </b-dropdown>
            <span v-if="field.errors.length" class="error-text">{{ field.errors[0] }}</span>
        </div>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'DropdownWithSearchInput',
    components: { ValidationProvider },
    props: {
        name: String,
        id: String,
        value: [String, Number],
        label: String,
        labelClass: String,
        errorClass: String,
        options: {
            type: Array,
            required: true,
        },
        required: Boolean,
        showAsterick: {
            type: Boolean,
            default: true
        },
        disableEmpty: Boolean,
        disabled: Boolean,
        placeholder: String,
        portal_data: {
            type: Object,
            required: false
        },
        field_name: {
            type: String,
            required: false
        },
    
    },
    data() {
        return {
            rules: '',
            dropdownOptions: [],
            dropdownOptionsOriginal: [],
            searchVal: '',
        };
    },
    methods: {
        optionClickHandler(value) {

            this.$emit('dropdownInput',
                value
            )
        },
        filterFunction({ target: { value } }) {
            this.searchVal = value
            if (value === '' || value === undefined)
                this.dropdownOptions = this.dropdownOptionsOriginal
            const filteredOptions = this.dropdownOptionsOriginal.
                filter((option) => {
                    if (option.value.toLowerCase().includes(value.toLowerCase()))
                        return true
                    else
                        return false
                })
            this.dropdownOptions = filteredOptions
        },
        getOptionValue(option) {
            return option?.value ?? option;
        },
        getOptionText(option) {
            return option?.text ?? this.ucwords(option);
        },
        ucwords(str) {
            str = str.toString().replaceAll('_', ' ');
            return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                return $1.toUpperCase();
            });
        },
    },
    watch: {
        "options": {
            handler: function (val) {
                this.dropdownOptions = val
                this.dropdownOptionsOriginal = val
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        getRules() {
            const rules = this.rules || '';

            if (this.required) {
                if (!rules.includes('required')) {
                    return rules.length > 0 ? rules + '|required' : 'required';
                }
            }

            return rules;
        },
        autocomplete() {
            if (this.newPassword) {
                return 'new-password';
            }

            if (this.disableAutocomplete) {
                return 'off';
            }

            return 'on';
        },
        diff_class() {
            let txt_class = 'dummy-text-class'
            if (this.portal_data && this.portal_data.pokeme && this.portal_data.data && this.field_name && this.portal_data.data[this.field_name] && this.portal_data.original_data && this.portal_data.data[this.field_name] != this.portal_data.original_data[this.field_name]) {
                txt_class = 'dark-red bold'
            }
            return txt_class
        },
        labelText() {
            return this.label + (this.required && this.showAsterick ? '*' : '');
        },
        valueText() {
            if ( this.value === null || this.value===undefined) {
                return `${this.placeholder}`
            }
            else {
                const result = this.dropdownOptionsOriginal.
                findIndex(item => item.id == this.value)
                return this.dropdownOptionsOriginal[result].value

            }
        }
    },
    updated() {
        console.log(this.valueText)
    }
};
</script>
