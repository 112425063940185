import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

export const convertToMonthString = (number) => {
    let day = null;
    switch (number) {
        case 0:
        day = "January";
        break;
        case 1:
        day = "February";
        break;
        case 2:
        day = "March";
        break;
        case 3:
        day = "April";
        break;
        case 4:
        day = "May";
        break;
        case 5:
        day = "June";
        break;
        case 6:
        day = "July";
        break;
        case 7:
        day = "August"
        break;
        case 8:
        day = "September";
        break;
        case 9:
        day = "October";
        break;
        case 10:
        day = "November";
        break;
        case 11:
        day = "December";
        break;
    }
    return day;
}

export const convertToDayString = (number) => {
    let day = null;
    switch (number) {
        case 0:
        day = "Sunday";
        break;
        case 1:
        day = "Monday";
        break;
        case 2:
        day = "Tuesday";
        break;
        case 3:
        day = "Wednesday";
        break;
        case 4:
        day = "Thursday";
        break;
        case 5:
        day = "Friday";
        break;
        case 6:
        day = "Saturday";
    }
    return day;
}

export default dayjs;
