export const aws_config = {
    aws_project_region: process.env.VUE_APP_aws_project_region,
    aws_cognito_region: process.env.VUE_APP_aws_cognito_region,
    aws_user_pools_id: process.env.VUE_APP_aws_user_pools_id,
    aws_user_pools_web_client_id: process.env.VUE_APP_aws_user_pools_web_client_id,
    aws_cognito_identity_pool_id: process.env.VUE_APP_aws_cognito_identity_pool_id,
    aws_user_files_s3_bucket: process.env.VUE_APP_aws_user_files_s3_bucket,
    aws_user_files_s3_bucket_region: process.env.VUE_APP_aws_user_files_s3_bucket_region,
    aws_appsync_graphqlEndpoint: process.env.VUE_APP_aws_appsync_graphqlEndpoint,
    aws_appsync_region: process.env.VUE_APP_aws_appsync_region,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            'REQUIRES_LOWERCASE',
            'REQUIRES_UPPERCASE',
            'REQUIRES_NUMBERS',
            'REQUIRES_SYMBOLS',
        ],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
};