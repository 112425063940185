<template>
    <div class="container page-container" :class="pageMeta.base_page" v-if="$store.state.initialized == true">
        <Header :isSidebarExpanded="isSidebarExpanded"/>
        <div class="bottom-slice">
            <div class="d-flex">
                <input id="sidebar-expand" class="function-input" type="checkbox" checked ref="expandControl" v-model="isChecked" @click="handleToggleClick"/>
                <Sidebar :isSidebarExpanded="isSidebarExpanded" @expandSidebar="handleExpandSidebar" ref="sidebar" />
                <div class="page-body" :class="pageMeta.base_page"><slot /></div>
            </div>
        </div>
      <RefreshBN />
    </div>
    <!-- Remove this -->
    <div
        style="display: flex; align-items: center; flex: 1; height: 60vh; max-height: 100%; width: 100%; justify-content: center;"
        v-else
    >
        <Loading />
    </div>
</template>

<script>
    import Loading from '@/components/general/loading/loading';

    import Sidebar from '@/components/layout/Sidebar';
    import Header from '@/components/layout/Header';
    import RefreshBN from '@/components/layout/RefreshBN';
    export default {
        name: 'Layout',
        components: { Header, Sidebar, Loading, RefreshBN },
        data() {
            return {
                isChecked: true
            }
        },
        computed: {
            isSidebarExpanded() {
                return this.isChecked;
            }
        },
        methods: {
            handleToggleClick(){
                this.$store.state.sideBartoggleValue=this.$refs.expandControl.checked
            },
            handleExpandSidebar() {
                const expandControl = this.$refs.expandControl;
                this.isChecked = true;

                if (!expandControl.checked) {
                    expandControl.checked = true;
                }
            },
            handleCheckChange() {
                const sidebar = this.$refs.sidebar;

                sidebar.closeAllPanels();
            }
        },
        async mounted() {
            setTimeout(() => {
                const expandControl = this.$refs.expandControl;
                expandControl && expandControl?.addEventListener('change', this.handleCheckChange);
            }, 1000);
        },
        beforeDestroy() {
            const expandControl = this.$refs.expandControl;
            expandControl && expandControl?.removeEventListener('change', this.handleCheckChange);
        }
    };
</script>
