<template>
    <div>
        <label :for="id" :class="labelClass">
            <!-- {{ label ? label : '' }} -->
            <input class="color"
                :name="name"
                type="checkbox"
                :id="id"
                :class="toggleClass"
                :disabled="disabled"
                :checked="!!value"
                :value="!!parseInt(value)"
                @input="$emit('input', $event.target.checked)"
            />
            <!-- {{ getYesOrNo(value) }} -->
            <span :class="spanClass"></span>
        </label>
        <span>{{ label ? label : '' }}</span>
    </div>
</template>

<script>
    export default {
        name: 'Toggle',
        props: {
            id: String,
            name: String,
            label: String,
            labelClass: String,
            toggleClass: String,
            value: [String, Number, Boolean],
            disabled: Boolean,
            spanClass: String,
        },
        methods: {
            getYesOrNo(value) {
                let text = 'no';
                if (value) {
                    text = 'yes';
                }
                return text;
            },
        },
    };
</script>
