<template>
    <!-- Copy styles from Radios and make new one for Checkboxes -->
    <span class="flex center" v-if="options">
        <span class="weight-600 dark-text right-30">{{ label }}</span>
        <span class="flex center right-30" v-for="(option, index) in options" :key="index">
            <input class="color"
                :value="option"
                type="checkbox"
                :name="name"
                :id="id"
                :class="inputClass"
                :checked="selected.includes(option)"
                @input="$emit('input', updateSelectedOptions(option))"
            />
            <label :class="labelClass" class="margin-0">{{ option }}</label>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'Checkboxes',
        data() {
            return {
                selected: []
            }
        },
        props: {
            id: String,
            name: String,
            value: [Boolean, Number, String, Array],
            label: String,
            inputClass: String,
            labelClass: String,
            disabled: Boolean,
            options: {
                type: Array,
                required: false,
            },
        },
        mounted() {
            /* Converts data that is not already in an array into an array.
               This is because old data was using radio buttons, not checkboxes. */
            this.selected = this.value
            if (!Array.isArray(this.selected)) {
                this.selected = [this.selected]
            }
        },
        methods: {
            // Adds and removes items selected in the "selected" array
            updateSelectedOptions(option) {
                if (!this.selected.includes(option)) {
                    this.selected.push(option)
                } else {
                    this.selected.splice(this.selected.indexOf(option), 1)
                }
                return this.selected
            },
        }
    }
</script>
