export function getCurrency(amount, useAccountingFormat = false) {
    if (typeof amount !== 'number') {
        return amount;
    }

    amount = amount / 100;

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencySign: useAccountingFormat ? 'accounting' : 'standard',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(amount);
}

export function getNumberFromCurrency(currency) {
    if (currency === '0') {
        return 0;
    }

    return parseInt(currency.replace(/[^0-9-]/g, ''));
}
