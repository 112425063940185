import axios from 'axios';
import Vue from 'vue';
import router from '../router';
import store from '../store';
import {timeoutFunctions} from "@/util/timeoutFunctions";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_DOMAIN,
    withCredentials: true
});

api.interceptors.request.use(
    (req) => {
        req.headers['x-build-id'] = store.getters.buildId || localStorage.getItem('buildId') || '';
        return req;
    },
    (err) => {
        console.log(err);
    }
);

let tracker

api.interceptors.response.use(
    async (res) => {
        if (res.headers['x-out-of-date'] === 'true') {
            store.commit('setShowRefreshBN', "newBNVersion")
        }
        if (tracker && typeof tracker.newRequest === 'function') {
            tracker.newRequest()
        } else {
            tracker = await timeoutFunctions()
        }
        return res;
    },
    async (err) => {
        if (err.response.status === 400) {
            const errors = err.response.data.fields.reduce((output, field) => {
                const primaryFieldMessage = field[Object.keys(field)[0]];
                output.push(primaryFieldMessage);
                return output;
            }, []);

            //Vue.toasted.error(err.response.data.message || 'Please check invalid fields');
            return {...err.response, data: errors};
        }

        if (err.response.status === 401) {
            if (router.currentRoute.name !== 'SignIn') {
                store.commit('clearUser');
                Vue.toasted.error('You must be signed in to view that page.');
                return router.replace('/auth/sign-in');
            } else {
                Vue.toasted.error(
                    err.response.data.message ||
                    'Invalid email or password.');
                return;
            }
        }

        return err.response;
    }
);



export default api;
