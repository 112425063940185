<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">
        <button class="close-corner-x" type="button" @click.prevent="cancel">
            <span class="material-icons-outlined dark-text">close</span>
        </button>
        <div class="new-user-roles">
            <h3 class="bottom-20">What type of user are you adding?</h3>
            <div class="flex gray-hover pad-10 radius-10 pointer" v-for="radio in radios" :key="radio.id">
                <div>
                    <input class="no-shadow color" type="radio" :id="radio.name" :value="radio.name" v-model="selected" />
                </div>
                <label class="left-5" :for="radio.name">
                    <span class="dark-text weight-600">{{ radio.name }}</span>
                    <p class="description margin-0">{{ radio.description }}</p>
                </label>
            </div>
            <div class="align-right">
                <button class="secondary margin-0" @click="beforeNavigation" v-if="selected">
                    Next <span class="material-icons-outlined">arrow_forward</span>
                </button>
            </div>
        </div>
        <!--        </template>-->
    </NoButtonModal>
</template>

<script>
    import { auth } from '@/util/apiRequests';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { Roles } from '@/util/globalConstants'

    export default {
        name: 'NewUserRoleModal',
        props: {
            open: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                selected: '',
                radios: []
            };
        },
        components: { NoButtonModal },
        methods: {
            cancel() {
                this.resetForm();
            },
            resetForm() {
                this.selected = '';
                this.$emit('close');
            },
            async beforeNavigation() {
                await this.$router.push({
                    name: 'UserDetail',
                    query: { role: this.selected },
                    params: { userId: 'new' }
                });
                this.resetForm();
            }
        },
        async created() {
            const res = await this.$api.get(auth.roles());
            let radios=res.data
            if(this.$store.state.user.role_id ===Roles.SUPER_ADMIN && radios.length>0){
                const lastItem=radios.pop();
                radios.unshift(lastItem)
            }
            this.radios = radios;
        }
    };
</script>
