<template>
    <ValidationProvider class="block" :rules="getRules" v-slot="field" :vid="name" :name="name">
        <label :for="id" :class="labelClass + ' ' + diff_class" v-if="labelText">{{ labelText }}</label>
        <span class="d-flex flex-column w-100">
            <select :class="diff_class" :name="name" :id="id" :value="value" @input="$emit('input', $event.target.value)" :disabled="disabled" @change="$emit('change', $event.target.value)">
                <option :disabled="disableEmpty" value="">{{placeholder}}</option>
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="getOptionValue(option)"
                    :disabled="option.disabled"
                >
                    {{ getOptionText(option) }}
                </option>
            </select>
            <span :class="errorClass">{{ field.errors[0] }}</span>
        </span>
        
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'Dropdown',
        components: { ValidationProvider },
        props: {
            name: String,
            id: String,
            value: [String, Number],
            label: String,
            labelClass: String,
            errorClass: String,
            options: {
                type: Array,
                required: true,
            },
            required: Boolean,
            showAsterick: {
                type: Boolean,
                default: true
            },
            disableEmpty: Boolean,
            disabled: Boolean,
            placeholder: String,
            portal_data: {
                type: Object,
                required: false
            },
            field_name: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                rules: '',
            };
        },
        methods: {
            getOptionValue(option) {
                return option?.value ?? option;
            },
            getOptionText(option) {
                return option?.text ?? this.ucwords(option);
            },
            ucwords(str) {
                str = str.toString().replaceAll('_', ' ');
                return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                    return $1.toUpperCase();
                });
            },
        },
        computed: {
            labelText() {
                return this.label + (this.required && this.showAsterick ? '*' : '');
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
            diff_class() {
                let txt_class = 'dummy-text-class'
                if (this.portal_data && this.portal_data.pokeme && this.portal_data.data && this.field_name && this.portal_data.data[this.field_name] && this.portal_data.original_data && this.portal_data.data[this.field_name] != this.portal_data.original_data[this.field_name]) {
                    txt_class = 'dark-red bold'
                }
                return txt_class
            },
        },
    };
</script>
