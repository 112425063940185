// import Vue from "vue";
import { clients } from "../../util/apiRequests";
import api from '@/util/api';

const state = () => ({
    calendar: undefined,
    currentView: undefined,
    clientList: [null],
    serviceList: [null],
    filters: {},
    currentStartDate: null,
    currentEndDate: null,
});
const mutations = {
   updateCalendar(state, {payload}){
        state.calendar = payload;
   },
   updateCurrentView(state, {payload}){
       state.currentView = payload;
   },

   updateClientList(state, {payload}){
       state.clientList = payload
   },
   updateServiceList(state, {payload}) {
       state.serviceList = payload
   },
   updateCurrentStartEndDate(state, {payload}){
       state.currentStartDate = payload.start
       state.currentEndDate = payload.end
   },
    updateFilters(state, {payload}){
       state.filters = payload
    }

};

const actions = {
    updateClientList({commit}, payload){
        commit('updateClientList');
    },

    updateServiceList({commit}, payload){
        commit('updateServiceList');
    },

    async getActiveClientList({commit, state, payload }) {
        let res = await api.get(clients.getActiveClients(), {});
        res.data.forEach((client) => (client.$isDisabled = false));
        commit('updateClientList', {
            payload: res.data
        });
    },

    async getServiceList({commit, state, rootState, payload }) {
        try {

            let level_id = rootState.user.company_id;
            let level = 'company';
            const res = await api.get(`/service_fees/list/${level}/${level_id}`);
            res.data = res.data.filter((service) => {
                if (service.code_type == 'service') {
                    return true;
                } else return false;
            });

            commit('updateServiceList', {
                payload: res.data

            });
        } catch (err) {
            console.log(err)
        }
    },
};


const getters = {

    clientList: (state) => {
        return state.clientList;
    },
    serviceList: (state) => {
        return state.serviceList;
    },
    filters: (state) => {
       return  state.filters
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
