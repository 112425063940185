/* eslint-disable no-unused-vars */
import Toasted from 'vue-toasted';
import api from '@/util/api';
import { clients, appts, file, settings } from '@/util/apiRequests';
import { AppointmentStatus } from '@/util/globalConstants';

/**
 * This file directly correlates with routerGuards.js via beforeEach in router/index.js go there for reference.
 * The functionality of this file is used with:
 * ClientOverview, ClientClinical, ClientInsurance, ClientDemographics, ClientDocuments, and ClientBilling
 */

const state = () => ({
    clientData: null,
    clientAppointments: [],
    attendance: null,
    license: null,
    clientProfilePhoto:null,
    tags: [],
    loaded: false,
    payerOptions: []
});
const mutations = {
    updateField(state, data) {
        state[data.field] = data.value;
    },
    updateClientLicenseId(state, data) {
        state.clientData[data.field] = data.value;
    },
    updateClientProfileId(state, data) {
        state.clientData[data.field] = data.value;
    }
};
const actions = {
    async getClient({ commit, rootState, dispatch }) {
        const res = await api.get(clients.getClient(rootState.current_client_id));
        commit('updateField', {
            field: 'clientData',
            value: res.data?.[0]
        });
      await  dispatch('getProfilePhotoFile');
      await dispatch('getLicense');
    },
    async getTags({ commit, rootState, dispatch }) {
        let tagSettingsId = null;
        try {
            const res = await api.get(settings.getList('company', rootState.user.company_id));
            tagSettingsId = res.data.find((element) => element.setting == 'tags')?.settings_id;
        } catch (error) {
            //
        }
        if (tagSettingsId) {
            try {
                const res = await api.get(settings.getSetting('tags', 'client', rootState.current_client_id));
                let tags = [];
                if (res.data.level == 'client') {
                    tags = res.data.value.split(',');
                }
                commit('updateField', {
                    field: 'tags',
                    value: tags
                });
            } catch (error) {
                // //this.$toasted.error('Could not retrieve client tags');
            }
        }
    },
    async getLicense({ commit, state }) {
        if (state && state.clientData && state.clientData.license_id) {
            const res = await api.get(file.getFile(state.clientData.license_id));
            commit('updateField', {
                field: 'license',
                value: res?.data?.file?.Body ? `data:image;base64, ${res?.data?.file.Body}` : null
            });
        } else {
            commit('updateField', {
                field: 'license',
                value: null
            });
        }
        commit('updateField', {
            field: 'loaded',
            value: true
        });
    },
    async getProfilePhotoFile({ commit, state }) {
        if (state && state.clientData && state.clientData.client_profile_photo_file_id) {
            const res = await api.get(file.getFile(state.clientData.client_profile_photo_file_id));
            commit('updateField', {
                field: 'clientProfilePhoto',
                value: res?.data?.file?.Body ? `data:image;base64, ${res?.data?.file.Body}` : null
            });
        } else {
            commit('updateField', {
                field: 'clientProfilePhoto',
                value: null
            });
        }
        commit('updateField', {
            field: 'loaded',
            value: true
        });
    },
    async getPayers({ commit }) {
        try {
            //
            const api_root = 'insurance_payers';
            const body = {
                criteria: {
                    page: { num_per_page: '10000', page_num: 1 }
                }
            };
            const res = await api.post(`/${api_root}`, body);
            commit('updateField', {
                field: 'payerOptions',
                value: res.data.rows
            });
        } catch (error) {
            //
            //
        }
    },
    async getAppointments({ commit, rootState }) {
        try {
            const res = await api.post(appts.getList(), {
                criteria: {
                    filter: { client_id: [String(rootState.current_client_id)] },
                    sort: { dayt_appt_start: 'Descending' },
                    page: { num_per_page: '10000', page_num: 1 }
                }
            });
            commit('updateField', {
                field: 'clientAppointments',
                value: res.data.rows
            });
            let attendance = { attended: 0, missed: 0 };
            if (res.data.rows.length > 0) {
                attendance = res.data.rows.reduce((output, appt) => {
                    let attended = output.attended ?? 0;
                    let missed = output.missed ?? 0;
                    if ((appt?.appt_status == AppointmentStatus.CANCELED) && appt?.isRequired == 1) {
                        missed++;
                    }
                    if ((appt?.appt_status == AppointmentStatus.NO_SHOW) && appt?.isRequired == 1) {
                        missed++;
                    }
                    if ((appt?.appt_status == 'Attended') && appt?.isRequired == 1) {
                        attended++;
                    }
                    return { attended: attended, missed: missed };
                }, {});
            }

            commit('updateField', {
                field: 'attendance',
                value: attendance
            });
        } catch (err) {
            // Toasted.error('Could not retrieve list of appointments');
        }
    }
};
const getters = {
    client: (state) => {
        return state.clientData;
    },
    clientLicense: (state) => {
        return state.license;
    },
    clientLicenseId: (state) => {
        return state.clientData.license_id;
    },
    getClientProfilePhoto: (state) => {
        return state.clientProfilePhoto;
    },
    getClientProfilePhotoId: (state) => {
        return state.clientData.client_profile_photo_file_id;
    },
    clientStatus: (state) => {
        if (state.clientData) {
            return state.clientData.status
        }
        return '';
    },
    clientFullName: (state) => {
        if (state.clientData?.first_name && state.clientData?.last_name) {
            return `${state.clientData?.last_name}, ${state.clientData?.first_name}`;
        }
        if (!state.clientData?.first_name && state.clientData?.last_name) {
            return state.clientData?.last_name;
        }
        if (state.clientData?.first_name && !state.clientData?.last_name) {
            return state.clientData?.first_name;
        }
        return 'You have a client with no name';
    },
    payerIds: (state) => {
        if (state.payerOptions.length > 0) {
            return state.payerOptions;
        }
        return [];
    },
    clientTags: (state) => {
        if (state.tags) {
            return state.tags.join(', ');
        }
        return '';
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
