<template>
    <div
        class="modal__backdrop"
        :class="{ modal__closed: !open, modal__open: open }"
        @mousedown.self="$emit('close', $event)"
    >
        <div class="modal__content">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NoButtonModal',

        props: {
            open: Boolean,
        },

        data() {
            return {
                escListener: null,
            };
        },

        methods: {
            createEscListener(self) {
                return function (e) {
                    if (e.key === 'Escape' && self.open) {
                        self.$emit('close');
                    }
                };
            },
        },

        created() {
            this.escListener = this.createEscListener(this);

            window.addEventListener('keydown', this.escListener);
        },

        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        },

        watch: {
            open(val) {
                if (val) {
                    document.querySelector('body').style.overflow = 'hidden';
                } else {
                    document.querySelector('body').style.overflow = 'auto';
                }
            },
        },
    };
</script>
