import { getBasePageName } from '@/util/getBasePageName';

export const pageMetaMixin = {
    computed: {
        pageMeta() {
            const availablePages = this.$store.getters.availablePages;
            const basePageName = getBasePageName(this.$route.path);

            const page = availablePages.find((page) => page.page === basePageName);

            if (!page) {
                return {
                    title: 'Sign In',
                };
            }

            return {
                id: page.id,
                page: page.page,
                permissions: page.permissions,
                title: page.page_title,
                icon: page.icon,
                jira_tickets: page.jira_tickets, // afv - alex - temp
                page_status: page.page_status, // afv - alex - temp
                filters: page.filters,
                needs_signatures: page.needs_signatures,
                base_page: basePageName,
                component_name: page.component_name,
                shareable_to_client: page.shareable_to_client,
            };
        },
    },
    mounted() {
        document.title =
            this.pageMeta?.title && this.pageMeta?.title !== 'Mobex Health Hub'
                ? `Mobex Health Hub - ${this.pageMeta.title}`
                : 'Mobex Health Hub';
    },
};
