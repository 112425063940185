function lockElements(el, binding) {
    // if (!binding.value) return;
    const tags = ['input', 'button', 'textarea', 'select'];
    tags.forEach((tagName) => {
        const nodes = el.getElementsByTagName(tagName)
        for (let i = 0; i < nodes.length; i++) {
            nodes[i].disabled = binding.value || nodes[i].disabled;
            nodes[i].tabIndex = !binding.value ? -1 : 0;
        }
    });
}

export const disableAll = {
    componentUpdated: lockElements,
    bind: lockElements,
    inserted: lockElements,
    update: lockElements
};