export const TimePickerKind = {
    Start: 'startTime',
    End: 'endTime',
};

export const AttendanceStatus = {
    None: null,
    Attended: 'Attended',
    Cancelled: 'Cancelled',
    NoShow: 'No Show',
    CancelledFeeWaived: "Cancelled - Excused",
    NoShowFeeWaived: "No Show - Excused",
};
