export const Crud = {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete'
};

export const EventType = {
    EVENT: 'EVENT',
    APPOINTMENT: 'APPOINTMENT'
};

export const AppointmentStatus = {
    UPCOMING: 'Upcoming',
    ATTENDED: 'Attended',
    NO_SHOW: 'No Show',
    CANCELED: 'Cancelled',
    CLIENT_CANCELED: 'Canceled (by client)',
    OFFICE_CANCELED: 'Canceled (by office)',
    SOME_OTHER_THING: 'Some Other Thing'
};

export const InvoiceStatus = {
    NOT_INVOICED: 'Not Invoiced',
    PENDING_PAYMENT: 'Pending Payment',
    PAID: 'Invoice Paid'
};

export const show_alert_prefix = 'show_alert_';


export const CounselorsErrorMessages = {
    SELECT_PRIMARY_COUNSELOR: 'Please select a primary counselor!',
    COUNSELORS_SAVE_FAILURE_MESSAGE: 'Could not save counselors!',
    COULDNT_RETRIEVE_COUNSELORS: 'Could not retrieve list of counselors.',
    COULDNT_RETRIEVE_CLIENT_COUNSELORS: 'Could not retrieve client counselors.',
    SECONDARY_COUNSELOR_FIELD_CANT_BE_EMPTY: 'Secondary Counselor field cannot be Empty!'
}

export const ClientOverviewErrorMessages = {
    FAILED_TO_SEND_PWR: 'Failed to send password reset email. Please try again later.',
    SENT_PWR_CLIENT: 'Sent password reset email to member',
    SENT_PWR_ADMIN: 'Sent password reset email to you',
    PIN_RESET:'Tablet Pin has been successfully reset',
    FAILED_TO_RESET_PIN:'Failed to reset pin. Please try again later'
}

export const Roles = {
    ADMIN: 1,
    CLINICIAN: 12,
    SUPPORT_STAFF: 14,
    BILLER: 15,
    CLIENT: 16,
    SUPER_ADMIN: 20,
}

export const SettingsOptionType = {
    TEXT_MULTI_SELECT: "text_multi-select"
}

export const Vitals = {
    HEART_RATE: "Heart Rate",
    BLOOD_SUGAR: "Blood Sugar",
    BLOOD_PRESSURE: "Blood Pressure",
    TEMPERATURE: "Body Temperature",
    PULSE_OX: "Pulse Oximeter",
}

export const VitalUnits = {
    heartRate: {
        name: Vitals.HEART_RATE,
        unit: ["bpm"],
        readingKey: ["pulse"]
    },
    gl: {
        name: Vitals.BLOOD_SUGAR,
        unit: ["mg/dL"],
        readingKey: ["bgValue"]
    },
    bp: {
        name: Vitals.BLOOD_PRESSURE,
        unit: ["mmHg", "mmHg"],
        readingKey: ["sys", "dia"]
    },
    temp: {
        name: Vitals.TEMPERATURE,
        unit: ["°F"],
        readingKey: ["temperature"]
    },
    spo2: {
        name: Vitals.PULSE_OX,
        unit: ["spo2"],
        readingKey: ["oxygen"]
    }
}

export const avatarColorOptions = ['#558069','#e39d27',  '#a85d9a', '#73AE66', '#2d6881', '#9B423B']
