<template>
    <span>
        <input
            type="checkbox"
            :id="id"
            :class="inputClass"
            class="right-5 color"
            :checked="!!value"
            @input="$emit('input', $event.target.checked)"
            :disabled="disabled"
        />
        <label class="margin-0 font-14" :for="id" :class="labelClass">{{ label }}</label>
    </span>
</template>

<script>
    export default {
        name: 'Checkbox',
        props: {
            id: String,
            value: [Boolean, Number, String],
            label: String,
            inputClass: String,
            labelClass: String,
            disabled: Boolean,
        },
    };
</script>

<style scoped></style>
