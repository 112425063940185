<template>
    <div class="container pe-0" ref="container">
        <div class="d-flex align-items-center" role="button">
            <div class="d-flex flex-column me-2 text-nowrap">
                <span class="fw-bold">{{ userName }}</span>
                <small class="fw-semibold">{{ userRole }}</small>
            </div>
           
            <img v-if="!!userProfileImg" :src="userProfileImg" class="user-profile-img"/>
            <div v-else class="d-flex justify-content-center align-items-center user-profile-avatar">
                <span class="material-icons">person_outline</span>
            </div>
            <!-- <b-avatar v-if="!!userProfileImg" rounded :src="userProfileImg"></b-avatar>
            <b-avatar v-else rounded src="https://placekitten.com/300/300"></b-avatar> -->

            <span class="material-symbols-outlined">expand_more</span>
        </div>
        <!-- <button class="create-button no-bg">
            {{ userName }} <span class="material-icons-outlined">expand_more</span>
        </button> -->
        <div class="header-right-dropdown" v-show="dropdownOpen" ref="dropdown">
            <div class="dropdown-btn">
                <button @click="goToMyProfile">My Profile</button>
            </div>
            <button @click="goToMySettings" aria-label="Go to my settings">
                My Settings
            </button>
            <div class="dropdown-btn">
                <button @click="signOut">Sign Out</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Roles } from '@/util/globalConstants'
    export default {
        name: 'MenuDropdown',
        props: {
            userId: {
                type: [Number, String],
                required: true
            },
            userName: {
                type: String,
                required: true
            },
            userRoleId: {
                type: Number,
                required: true
            },
            userProfileImg: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                dropdownOpen: false
            };
        },
        computed: {
            userRole() {
                let userRole = '';
                if (!this.userRoleId) {
                    return '';
                }

                switch (this.userRoleId) {
                    case Roles.SUPER_ADMIN:
                        userRole='Super Administrator';
                        break;
                    case Roles.ADMIN :
                        userRole = 'Administrator';
                        break;
                    case Roles.CLINICIAN :
                        userRole = 'Clinician';
                        break;
                    case Roles.SUPPORT_STAFF :
                        userRole = 'Support Staff';
                        break;
                    case Roles.BILLER :
                        userRole = 'Biller';
                        break;
                    case Roles.CLIENT :
                        userRole = 'Client';
                        break;
                }
                return userRole;
            }
        },
        methods: {
            goToMySettings() {
                this.$router.push({ name: 'UserSettings' });
            },
            async goToMyProfile() {
                await this.$router.push({ name: 'UserDetail', params: { userId: this.userId } });
                this.dropdownOpen = false;
            },
            async signOut() {
                try {
                    await this.$api.post('/auth/sign-out');
                } catch (err) {
                    console.error(err);
                } finally {
                    document.cookie = 'chirp_notes_id=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    this.$store.commit('clearUser');
                    this.$store.commit('clearGoogleTokens');
                    await this.$store.dispatch('recent/clearStore');
                    await this.$router.push({ name: 'SignIn' });
                    window.location.reload();
                }
            },
            closeDropdown() {
                this.dropdownOpen = false;
            },
            setDropdown(event) {
                this.dropdownOpen = this.$refs.container.contains(event.target);
            }
        },
        mounted() {
            document.addEventListener('click', this.setDropdown);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.setDropdown);
        }
    };
</script>

<style scoped>
    .user-profile-img {
        width: 36px;
        height: 35px;
        border-radius: 5px;
        box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
    }
    .user-profile-avatar {
        background: rgb(81 85 195 / 83%);
        color: #ffffff;
        width: 36px;
        height: 35px;
        border-radius: 5px;
        box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
    }

    .material-symbols-outlined {
        font-size: 21px;
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 20
    }
</style>
