// add a data_id attribute to each element in the array

import {generateId} from "@/util/genericUtilityFunctions";

Object.defineProperty(Object.prototype, 'addDataId', {
        value: function () {
            if (this instanceof Array) {

                this.forEach((element) => {
                    element.data_id = element.data_id || generateId();
                });

                return this;
            } else {
                this.data_id = this.data_id || generateId();

                return this;
            }
        }
    }
);