<template>
    <ValidationProvider :rules="getRules" v-slot="field" :vid="name" :name="name">
        <label :for="id" :class="labelClass">{{ labelText }}</label>
        <select :name="name" :id="id" :value="value" @input="$emit('input', $event.target.value)" :disabled="disabled">
            <option :disabled="disableEmpty" value="">{{placeholder}}</option>
            <option
                v-for="(option, index) in options"
                :key="index"
                :value="getOptionValue(option)"
                :disabled="disabled"
            >
                {{ getOptionText(option) }}
            </option>
        </select>
        <span :class="errorClass">{{ field.errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'UsStates',
        components: { ValidationProvider },
        props: {
            name: String,
            id: String,
            value: [String, Number],
            label: String,
            labelClass: String,
            errorClass: String,
            required: Boolean,
            disableEmpty: Boolean,
            disabled: Boolean,
            placeholder: String
        },
        data() {
            return {
                rules: '',
                options:['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
            };
        },
        methods: {
            getOptionValue(option) {
                return option?.value ?? option;
            },
            getOptionText(option) {
                return option?.text ?? option;
            },
        },
        computed: {
            labelText() {
                return this.label + (this.required ? '*' : '');
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
        },
    };
</script>
