<template>
    <button type="button" >
        <Loading
            :outerHeight="'21px'"
            :outerWidth="'21px'"
            :height="'21px'"
            :width="'21px'"
            :color="'white transparent transparent transparent'"
            :borderWidth="'3px'"
        />
    </button>
</template>

<script>
    import Loading from '@/components/general/loading/loading';
    export default {
        name: 'LoadingButton',
        components: { Loading }
    };
</script>
