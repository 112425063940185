<template>
    <ValidationProvider class="block" :rules="getRules" v-slot="field" :vid="name" :name="name">
        <label :for="id" :class="labelClass + ' ' + diff_class"
            >{{ labelText }}
            <span v-if="!!labelOptionalText" :class="labelOptionalTextClass">{{ labelOptionalText }}</span></label
        >
        <span class="d-flex flex-column w-100">
            <input
                :name="name"
                :maxlength="maxLength"
                :type="type"
                :id="id"
                :class="inputClass + ' ' + diff_class"
                :placeholder="placeholder"
                :value="value"
                :disabled="disabled"
                @input="$emit('input', $event.target.value)"
                @change="$emit('change', { id: $event.target.id, value: $event.target.value })"
                :autocomplete="autocomplete"
                @blur="$emit('blur', $event)"
                @keydown="$emit('keydown', $event)"
                :style="input_style"
            />
            <span v-if="field.errors.length" :class="errorClass">{{ field.errors[0] }}</span>
            <span
                v-if="true_type == 'password' && type == 'text'"
                @click="eye_password"
                class="material-icons pass-viz yes"
                >visibility</span
            >
            <span
                v-if="true_type == 'password' && type == 'password'"
                @click="eye_password"
                class="material-icons-outlined pass-viz"
                >visibility_off</span
            >
            <span v-if="!!unitPlaceholderText" class="unit-placeholder-text">{{ unitPlaceholderText }}</span>
        </span>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'Input',
        components: { ValidationProvider },
        props: {
            type: String,
            true_type: String,
            maxLength: String,
            name: String,
            id: String,
            label: String,
            labelClass: String,
            inputClass: String,
            errorClass: String,
            value: [String, Number, Object, Array],
            placeholder: String,
            rules: String,
            required: Boolean,
            showAsterick: {
                type: Boolean,
                default: true,
            },
            labelOptionalText: {
                type: String,
                default: '',
            },
            labelOptionalTextClass: String,
            disabled: Boolean,
            disableAutocomplete: Boolean,
            newPassword: Boolean,
            input_style: String,
            portal_data: {
                type: Object,
                required: false,
            },
            field_name: {
                type: String,
                required: false,
            },
            unitPlaceholderText: {
                type: String,
                default: null,
            },
        },
        methods: {
            eye_password() {
                if (this.true_type == 'password') {
                    if (this.type == 'password') {
                        this.type = 'text';
                    } else {
                        this.type = 'password';
                    }
                }
            },
        },
        computed: {
            labelText() {
                return this.label ? this.label + (this.required && this.showAsterick ? '*' : '') : '';
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
            autocomplete() {
                if (this.newPassword) {
                    return 'new-password';
                }

                if (this.disableAutocomplete) {
                    return 'off';
                }

                return 'on';
            },
            diff_class() {
                let txt_class = 'dummy-text-class';
                if (
                    this.portal_data &&
                    this.portal_data.pokeme &&
                    this.portal_data.data &&
                    this.field_name &&
                    this.portal_data.data[this.field_name] &&
                    this.portal_data.original_data &&
                    this.portal_data.data[this.field_name] != this.portal_data.original_data[this.field_name]
                ) {
                    txt_class = 'dark-red bold';
                }
                return txt_class;
            },
        },
    };
</script>
