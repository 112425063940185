import Vue from 'vue';

const defaultFilter = {
    filter: {},
    sort: {},
    page: {
        num_per_page: 10,
        page_num: 1,
    },
    date_range: { start_date: '', end_date: '' },
    search: {},
};

const getPersistedFilters = () => {
    const local = window.localStorage.getItem('page_prefs');

    if (!local) {
        return {};
    }

    try {
        const persistedFilters = JSON.parse(local);
        return persistedFilters;
    } catch (err) {
        return {};
    }
};

const state = () => ({
    criteriaPages: getPersistedFilters(),
    overrideCriteriaPages: {},
});

const mutations = {
    applyDefaultFilter(state, payload) {
        if (typeof payload === 'string') {
            Vue.set(state.criteriaPages, payload, { ...defaultFilter });
        } else {
            Vue.set(state.criteriaPages, payload.stateKey, {
                ...defaultFilter,
                default_date_range: payload.defaultDateRange,
            });
        }

        window.localStorage.setItem('page_prefs', JSON.stringify(state.criteriaPages));
    },
    updateFilter(state, { stateKey, criteria }) {
        if (state.criteriaPages[stateKey]) {
            state.criteriaPages[stateKey] = criteria;
        } else {
            Vue.set(state.criteriaPages, stateKey, criteria);
        }

        window.localStorage.setItem('page_prefs', JSON.stringify(state.criteriaPages));
    },
    applyDefaultDateSort(state, { stateKey, customDateKey = 'date' }) {
        if (state.criteriaPages[stateKey]) {
            Vue.set(state.criteriaPages[stateKey], 'sort', { [customDateKey]: 'Descending' });
        } else {
            Vue.set(state.criteriaPages, stateKey, {
                ...defaultFilter,
                sort: {
                    [customDateKey]: 'Descending',
                }
            });
        }
    },
    clearFilter(state, stateKey) {
        Vue.delete(state.criteriaPages, stateKey);

        window.localStorage.setItem('page_prefs', JSON.stringify(state.criteriaPages));
    },
    applyOverride(state, { stateKey, criteria }) {
        Vue.set(state.overrideCriteriaPages, stateKey, criteria);
    },
};

const actions = {};

const getters = {
    criteria: (state) => (stateKey) => {
        return state.criteriaPages[stateKey] || null;
    },
    pageData: (state) => (stateKey) => {
        const criteria = state.criteriaPages[stateKey];

        if (!criteria) {
            return null;
        }

        return criteria.page || null;
    },
    overrideCriteria: (state) => (stateKey) => {
        return state.overrideCriteriaPages[stateKey] || null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
