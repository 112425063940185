import Vue from 'vue';

const state = () => ({
    dropdownOpen: false,
    newClientModalOpen: false,
    newUserRoleModalOpen: false,
    new_client_data : null
});

const mutations = {
    openDropdown(state) {
        state.dropdownOpen = true;
    },
    openNewClientModal(state) {
        state.dropdownOpen = true;
        state.newClientModalOpen = true;
    },
    openNewClientModal2(state, payload) {
        state.dropdownOpen = true;
        state.newClientModalOpen = true;
        if (payload) {
            state.new_client_data = payload
        }
    },
    openNewUserRoleModal(state) {
        state.dropdownOpen = true;
        state.newUserRoleModalOpen = true;
    },
    closeAll(state) {
        state.newClientModalOpen = false;
        state.newUserRoleModalOpen = false;
        state.dropdownOpen = false;
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
