<template>
    <div>
        <input
            type="radio"
            class="function-input"
            @click="$emit('accordionOpen', $event)"
            name="menu-drop"
            :id="`client-${pageName}`"
        />

        <label :for="`client-${pageName}`" class="menu-drop-label">
            <div class="d-flex flex-column align-items-center dropdown-item-container">
                <span v-if="!!pageIconStyle"><img :src="pageIcon" :style="pageIconStyle"/></span>
                <span v-else class="material-icons-outlined icon_sub_menu_sidebar_item">{{ pageIcon }}</span>
                <span v-if="isSidebarExpanded" class="text_sub_menu_sidebar_item mt-2">{{ menuText }}</span>
            </div>
            <span class="arrow"><img src="@/assets/px/sidebar-icons-images/chevron-right.png" /></span>
            <!-- <span class="material-icons-outlined arrow text-white">chevron_right</span> -->
        </label>
        
        <div class="sub-links">
            <router-link v-for="link in submenuLinks" :key="link.id" :to="getLinkUrl(link)">
                <span class="text-white">{{ link.menuText }}</span>
            </router-link>
        </div>

        <div v-if="showBottomBorder" class="sidebar_menu_divider mx-3 my-3"></div>
    </div>
</template>

<script>
    export default {
        name: 'SubmenuSidebarItem',
        props: {
            pageName: {
                type: String,
                required: true
            },
            pageIcon: {
                type: String,
                required: true
            },
            pageIconStyle: {
                type: Object,
                required: true
            },
            menuText: {
                type: String,
                required: true
            },
            submenuLinks: {
                type: Array,
                required: true
            },
            showBottomBorder: {
                type: Boolean,
                required: true
            },
            isSidebarExpanded: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            getLinkUrl(link) {
                if (link.pageName === 'UserDetail') {
                    return { name: link.pageName, params: { userId: 'new' } };
                } else {
                    return { name: link.pageName };
                }
            }
        }
    };
</script>

<style scoped>
    .dropdown-item-container {
        padding: 6px 20px;
    }

    .icon_sub_menu_sidebar_item {
        font-size: 2.9rem;
        color: #FFFFFF;
    }
    .text_sub_menu_sidebar_item {
        font-size: smaller;
        color: #FCFCFF;
        font-weight: 600;
        line-height: 18px;
    }
    .sidebar_menu_divider {
        border: 1px solid #4940c5;
    }
</style>
