export const vIfWorkableModalMixin = {
    methods: {
        openModalOverlay() {
            let elem = document.querySelector('.modalIsOpen');
            if (elem == null) {
                elem = document.createElement('div');
                elem.classList.add('modalIsOpen', 'hidden', 'lg:block');
                document.body.appendChild(elem);
                document.body.style.overflow = 'hidden';
            }
        },
        closeModalOverlay() {
            let elem = document.querySelector('.modalIsOpen');
            if (elem != null) {
                elem.parentNode.removeChild(elem);
                document.body.style.overflow = 'auto';
            }

        }
    }
}