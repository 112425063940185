<template>
    <div>
        <label>
            <router-link :to="{ name: pageName }">
                <div class="d-flex flex-column align-items-center">
                    <span v-if="!!pageIconStyle"><img :src="pageIcon" :style="pageIconStyle"/></span>
                    <span v-else class="material-icons-outlined icon_sidebar_item">{{ pageIcon }}</span>
                    <span v-if="isSidebarExpanded" class="mt-2 text_sidebar_item">{{ menuText }}</span>
                </div>         
                <span v-if="(pageName=='Dashboard'&&!count==0)" class="memory-count">{{count}}</span>
            </router-link>
        </label>
        <div class="sidebar_menu_divider mx-3 my-3"></div>
    </div>
</template>

<script>
import { memory } from '@/util/apiRequests';
    export default {
        name: 'SidebarItem',
        props: {
            pageName: {
                type: String,
                required: true
            },
            pageIcon: {
                type: String,
                required: true
            },
            pageIconStyle: {
                type: Object,
                required: true
            },
            menuText: {
                type: String,
                required: true
            },
            showBottomBorder: {
                type: Boolean,
                required: true
            },
            isSidebarExpanded: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                count: 0,
                interval: undefined,
            };
        },
        methods: {
            async getMemory() {
                try {
                    // let res = await this.$api.get(memory.getNewMemory(), {
                    //     params: {
                    //         companyId: this.$store.state.user.company_id,
                    //         userId: this.$store.state.user.id,
                    //     }
                    // });
                    // if(res){
                    //     this.count = res.data.newMemoryCount;
                    // }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async created() {
            this.getMemory();
            this.interval = setInterval(this.getMemory, 5000);
        },  
    };
</script>

<style scoped>
    .icon_sidebar_item {
        font-size: 2.9rem;
        color: #FFFFFF;
    }

    .text_sidebar_item {
        font-size: smaller;
        color: #FCFCFF;
        font-weight: 600;
        line-height: 18px;
    }

    .sidebar_menu_divider {
        border: 0.1px solid #4940c5;
    }
</style>
