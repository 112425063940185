<template>
    <ValidationProvider :rules="getRules" v-slot="field" :vid="name" :name="name">
        <label :for="id" :class="labelClass">{{ labelText }}</label>
        <textarea
            ref="text_area"
            :name="name"
            :type="type"
            :id="id"
            :class="inputClass"
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled"
            :oninput="oninput"
            :style="inputStyle"
            @input="$emit('input', $event.target.value)"
            @change="$emit('change', {id:$event.target.id, value:$event.target.value})"
            :autocomplete="autocomplete"
            @blur="$emit('blur', $event)"
            @keydown="$emit('keydown', $event)"
            :maxlength="limit"
        />
        <div class='textarea-print-only'>{{ value }}</div>
        <div class="do-not-print-me char-counter font-12" style="text-align:right;">{{ characterCounter(value, limit) }}</div>
        <span v-if="field.errors.length" :class="errorClass">{{ field.errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'ExpandableTextArea',
        components: { ValidationProvider },
        props: {
            type: String,
            name: String,
            id: String,
            label: String,
            labelClass: String,
            inputClass: String,
            errorClass: String,
            value: [String, Number, Object, Array],
            placeholder: String,
            rules: String,
            required: Boolean,
            disabled: Boolean,
            disableAutocomplete: Boolean,
            newPassword: Boolean,
            oninput: String,
            inputStyle: String,
            limit: {
                type: Number,
                default: 1000
            }
        },
        data(){
            return {
                ref_text_area: null,
                observer: null,
            }

        },
        computed: {
            labelText() {
                return this.label + (this.required ? '*' : '');
            },
            getRules() {
                const rules = this.rules || '';

                if (this.required) {
                    if (!rules.includes('required')) {
                        return rules?.length > 0 ? rules + '|required' : 'required';
                    }
                }

                return rules;
            },
            autocomplete() {
                if (this.newPassword) {
                    return 'new-password';
                }

                if (this.disableAutocomplete) {
                    return 'off';
                }

                return 'on';
            },
        },
        methods: {
            characterCounter(val, limit) {
                return typeof val === 'string' && val.length + " / " + limit + " character(s) found";
            },
            //Expand the text area whenever the element is observed, regardless of section.
            onElementObserved(entries) {
                let entry = entries[0].target.children[1];
                entry.dispatchEvent(new Event('input'));
            },
            //Update textarea whenever the window is resized
            resizeHandler() {
                this.$refs.text_area.dispatchEvent(new Event('input'));
            }
        },
        created() {
            this.observer = new IntersectionObserver(this.onElementObserved, {
                root: this.$el,
                threshold: 1.0,
            });

        },
        mounted(){
                //this is one way of doing it, creating a set, then watching for the existence for the element
                this.$set(this.$data, 'ref_text_area', this.$refs.text_area);
                this.observer.observe(this.$el);
                window.addEventListener("resize", this.resizeHandler);
        },
        beforeDestroy() {
            this.observer.disconnect();
            window.removeEventListener("resize", this.resizeHandler);
        },
        watch: {
                ref_text_area: {
                    handler(newVal, oldVal){
                        // Do something when the DOM changes!
                        if(newVal){
                            newVal.style.height = newVal.scrollHeight + 'px';
                        }
                    },
                    deep: true
            }
        }
    };
</script>
